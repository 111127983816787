import React from "react";
import styled from "styled-components";
import InvitationForm from "./invitationForm";

const InvitationsPrivate = ({ wedding }) => {
  return (
    <Invitation>
      <p className="title">Invitations</p>
      <InvitationForm wedding={wedding} />
    </Invitation>
  );
};

export default InvitationsPrivate;

const Invitation = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  margin: 0 auto;
  .title {
    font-weight: 600;
    color: #ae7288;
    font-size: larger;
  }
`;
