import React from "react";
import styled from "styled-components";

import GetStarted from "../components/getStarted";
import Features from "../components/features";

// 2 - Demo;
// 3 - Features;
// 4 - Customization;
// 5 - FAQ;
// 6 - CTA;
// 7 - Footer;

const Home = () => {
  return (
    <HomePage>
      <GetStarted />
      {/* <Features /> */}
    </HomePage>
  );
};

export default Home;

const HomePage = styled.div`
  display: flex;
  flex-direction: column;
`;
