import React, { useState } from "react";
import styled from "styled-components";
import PrePayment from "./prePayment";
import RazorpayAPI from "../api/razorpay.api";
import processing from "../media/paymentProcessing.gif";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const Payment = ({ wedding }) => {
  const [isPending, setIsPending] = useState(true);

  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const navigate = useNavigate();
  const redirect = () => {
    navigate("/weddings");
  };

  const dispatch = useDispatch();
  const checkout = () => {
    setIsPending(false);
    RazorpayAPI.newOrder(
      setIsSuccess,
      setIsFailed,
      wedding._id,
      dispatch
    );
  };
  return (
    <PaymentContainer>
      {isPending && <PrePayment checkout={checkout} />}
      {/* {isProcessing && <img src={processing} alt="" className="gif" />} */}
      {isSuccess && (
        <div className="success">
          <p className="thank-you">Thanks for the Purchase 🎉</p>
          <button className="redirect" onClick={redirect}>
            Explore
          </button>
        </div>
      )}

      {!isSuccess & !isPending & !isFailed && (
        <img src={processing} alt="" className="gif" />
      )}
    </PaymentContainer>
  );
};

export default Payment;

const PaymentContainer = styled.div`
  display: flex;
  width: 80vw;
  height: 65vh;
  margin: auto;
  border-radius: 5px;
  padding: 10px;
  align-items: center;
  justify-content: center;

  .gif {
    width: 100%;
    margin: auto;
  }

  .thank-you {
    font-size: 45px;
    font-weight: 600;
  }

  .redirect {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-top: 3px;
    font-weight: 600;
    border: none;
    width: 150px;
    height: 40px;
    background-color: #e8355f;
    color: #feecf2;
    cursor: pointer;
    border-radius: 3px;
    justify-content: center;
    gap: 10px;
  }
`;
