import axios from "axios";
import { mediaActions } from "../store/mediaSlice";

axios.defaults.withCredentials = true;

// const BASE_URL = "http://localhost:8000/";
const BASE_URL = "https://api-wedink-9u29u.ondigitalocean.app/";

const fetchAllMedia = async (dispatch, setIsLoading) => {
  try {
    const response = await axios.create().get(`${BASE_URL}media/`);
    if (response.data.success) {
      dispatch(mediaActions.setAllMedia(response.data.media));
    }
  } catch (err) {
    console.log(err.message);
  } finally {
    setIsLoading(false);
  }
};

const uploadMedia = async (dispatch, setIsLoading, data, setImage) => {
  try {
    const response = await axios.create().post(`${BASE_URL}media/new`, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    if (response.data.success) {
      dispatch(mediaActions.refreshMedia());
      setImage({});
    }
  } catch (err) {
    console.log(err.message);
  } finally {
    setIsLoading(false);
  }
};

const deleteMedia = async (setIsLoading, mediaID, dispatch) => {
  try {
    const response = await axios
      .create()
      .delete(`${BASE_URL}media/${mediaID}/delete`, {
        // headers: {
        //   "content-type": "multipart/form-data",
        // },
      });
    if (response.data.success) {
      dispatch(mediaActions.refreshMedia());
    }
  } catch (err) {
    console.log(err.message);
  } finally {
    setIsLoading(false);
  }
};

const MediaAPI = {
  fetchAllMedia,
  uploadMedia,
  deleteMedia,
};

export default MediaAPI;
