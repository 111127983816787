import React, { useState } from "react";
import styled from "styled-components";
import EventAPI from "../api/event.api";
import { useDispatch } from "react-redux";

const EventForm = ({ weddingID, toggleAdding }) => {
  const [eventName, setEventName] = useState("");
  const [locationText, setLocationText] = useState("");
  const [eventDesc, setEventDesc] = useState("");
  const [locationLink, setLocationLink] = useState("");
  const [dateTime, setDateTime] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  console.log(dateTime);

  const [section, setSection] = useState(1);

  const dispatch = useDispatch();

  const moveBack = () => {
    if (section > 1) {
      setSection((prev) => prev - 1);
    }
  };

  const moveNext = () => {
    if (section < 3) {
      setSection((prev) => prev + 1);
    }
  };

  const saveEvent = (e) => {
    e.preventDefault();

    const data = {
      eventName: eventName,
      eventDesc: eventDesc,
      locationText: locationText,
      locationLink: locationLink,
      dateTime: dateTime,
      weddingID: weddingID,
    };

    setIsLoading(true);
    EventAPI.createEvent(dispatch, setIsLoading, data);
    toggleAdding();
  };
  return (
    <FormContainer>
      <form className="event-form">
        {section === 1 && (
          <div className="form-sec">
            <input
              type="text"
              value={eventName}
              onChange={(e) => setEventName(e.target.value)}
              className="input"
              placeholder="Name"
            />
            <input
              type="text"
              value={eventDesc}
              onChange={(e) => setEventDesc(e.target.value)}
              className="input"
              placeholder="About the event"
            />
          </div>
        )}
        {section === 2 && (
          <div className="form-sec">
            <input
              type="text"
              value={locationText}
              onChange={(e) => setLocationText(e.target.value)}
              className="input"
              placeholder="Location Name"
            />
            <input
              type="text"
              value={locationLink}
              onChange={(e) => setLocationLink(e.target.value)}
              className="input"
              placeholder="Maps location Link"
            />
          </div>
        )}

        {section === 3 && (
          <div className="form-sec">
            <label htmlFor="datetime" className="label">
              Date and Time :
            </label>
            <input
              type="datetime-local"
              id="datetime"
              value={dateTime}
              onChange={(e) => setDateTime(e.target.value)}
              className="input"
              placeholder="Date"
            />
          </div>
        )}
      </form>
      <div className="nav-buttons">
        {section != 1 && (
          <button className="nav-btn" onClick={moveBack}>
            Back
          </button>
        )}
        {section === 3 ? (
          <button className="nav-btn" onClick={saveEvent}>
            Save
          </button>
        ) : (
          <button className="nav-btn" onClick={moveNext}>
            Next
          </button>
        )}
      </div>
    </FormContainer>
  );
};

export default EventForm;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .event-form {
    display: flex;
    flex-direction: column;
  }
  .form-sec {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .input {
    border-radius: 7px;
    background: none;
    height: 30px;
    border: 2px solid black;
    font-weight: 600;
    padding-left: 5px;
    border: 2px solid #ae7288;
  }
  .input:focus {
    padding-left: 10px;
    font-weight: bold;
  }
  .label {
    font-weight: 600;
  }

  .input:placeholder-shown {
    padding-left: 10px;
  }
  .nav-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .nav-btn {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-top: 3px;
    font-weight: 600;
    border: none;
    width: 150px;
    height: 40px;
    background-color: #e8355f;
    color: #feecf2;
    cursor: pointer;
    border-radius: 3px;
    justify-content: center;
    gap: 10px;
  }
`;
