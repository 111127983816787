import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Login from "./login";
import { useParams } from "react-router-dom";
import WeddingAPI from "../api/wedding.api";

import WeddingPrivateNav from "../components/weddingPrivateNav";
import CouplePrivate from "../components/couplePrivate";
import PeoplePrivate from "../components/peoplePrivate";
import EventsPrivate from "../components/eventsPrivate";
import GuestsPrivate from "../components/guestsPrivate";
import InvitationsPrivate from "../components/invitationsPrivate";
import AppearanceEditor from "../components/appearanceEditor";

import Payment from "../components/payment";

const SingleWedding = () => {
  const wedding = useSelector((state) => state.wedding.currentWedding);
  const isUpdated = useSelector((state) => state.wedding.isUpdated);

  const [tab, setTab] = useState("0");

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [isLoading, setIsLoading] = useState(true);

  const username = useParams().username;
  const dispatch = useDispatch();

  const TABS_DATA = [
    <CouplePrivate wedding={wedding} />,
    <PeoplePrivate people={wedding.people} weddingID={wedding._id} />,
    <EventsPrivate wedding={wedding} />,
    <GuestsPrivate wedding={wedding} />,
    <AppearanceEditor wedding={wedding} />,
    <InvitationsPrivate wedding={wedding} />,
    <Payment wedding={wedding} />,
  ];

  useEffect(() => {
    WeddingAPI.fetchWedding(dispatch, setIsLoading, username);
  }, [isUpdated]);

  if (isLoading) {
    return <></>;
  }

  return isLoggedIn ? (
    <WeddingsContainer>
      <WeddingPrivateNav wedding={wedding} setTab={setTab} />
      {TABS_DATA[parseInt(tab)]}
      <br></br>
      <br></br>
      <br></br>
    </WeddingsContainer>
  ) : (
    <Login />
  );
};

export default SingleWedding;

const WeddingsContainer = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  background-color: ${(props) => props.theme.backgroundColor};
  color: ${(props) => props.theme.contentFontColor};
`;
