import React, { useState } from "react";
import styled from "styled-components";
import Data from "./_data";
import OptionInput from "./optionInput";
import ColorPicker from "./colorPicker";
import AppearanceAPI from "../api/appearance.api";
import { useDispatch } from "react-redux";
import Preview from "./appearancePreview";
import ThemeSelector from "./themeSelector";

const AppearanceEditor = ({ wedding }) => {
  const [curTheme, setCurTheme] = useState(wedding.theme[0]);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggleEdit = () => {
    setIsEditing((prev) => !prev);
  };

  const onThemeChange = (e) => {
    setCurTheme({ ...curTheme, [e.target.name]: e.target.value });
  };

  const dispatch = useDispatch();

  const save = (e) => {
    e.preventDefault();
    setIsLoading(true);
    AppearanceAPI.updateAppearance(
      dispatch,
      curTheme._id,
      setIsLoading,
      curTheme,
      toggleEdit
    );
  };
  return (
    <Editor>
      <ThemeSelector
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        theme={curTheme}
      />

      {isEditing && (
        <div>
          <p className="title">Preview</p>
          <Preview theme={curTheme} wedding={wedding} />
        </div>
      )}
      {isEditing && (
        <>
          <div className="forms">
            <form className="font-form">
              <div className="fonts">
                <OptionInput
                  value={curTheme.titleFontFamily}
                  onChange={onThemeChange}
                  options={Data.fontOptions}
                  defaultValue={curTheme.titleFontFamily}
                  label="Title font"
                  isEditing={isEditing}
                  name="titleFontFamily"
                />
                <OptionInput
                  options={Data.fontOptions}
                  defaultValue={curTheme.contentFontFamily}
                  label="Content font"
                  value={curTheme.contentFontFamily}
                  isEditing={isEditing}
                  name="contentFontFamily"
                  curTheme={curTheme}
                  onChange={onThemeChange}
                />
              </div>
              <div className="colors">
                <ColorPicker
                  value={curTheme.titleFontColor}
                  label="Title"
                  isEditing={isEditing}
                  setCurTheme={setCurTheme}
                  curTheme={curTheme}
                  name="titleFontColor"
                />
                <ColorPicker
                  value={curTheme.contentFontColor}
                  label="Content"
                  isEditing={isEditing}
                  setCurTheme={setCurTheme}
                  curTheme={curTheme}
                  name="contentFontColor"
                />
                <ColorPicker
                  value={curTheme.backgroundColor}
                  label="Background"
                  isEditing={isEditing}
                  setCurTheme={setCurTheme}
                  curTheme={curTheme}
                  name="backgroundColor"
                />
                <ColorPicker
                  value={curTheme.buttonTextColor}
                  label="Button text"
                  isEditing={isEditing}
                  setCurTheme={setCurTheme}
                  curTheme={curTheme}
                  name="buttonTextColor"
                />
                <ColorPicker
                  value={curTheme.buttonBackgroundColor}
                  label="Button Background"
                  isEditing={isEditing}
                  setCurTheme={setCurTheme}
                  curTheme={curTheme}
                  name="buttonBackgroundColor"
                />
              </div>
            </form>
          </div>
        </>
      )}
      {isEditing ? (
        <button className="save" onClick={save}>
          {isLoading ? <div class="loader"></div> : "Save"}
        </button>
      ) : (
        <button className="save" onClick={toggleEdit}>
          Customize
        </button>
      )}
    </Editor>
  );
};

export default AppearanceEditor;

const Editor = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  margin: 0 auto;
  gap: 20px;
  margin-bottom: 30px;
  .edit-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .title {
    color: #ae7288;
    font-weight: 600;
    font-size: larger;
  }
  .loader {
    border: 8px solid #e8355f; /* Light grey */
    border-top: 10px solid #feecf2; /* Blue */
    border-radius: 50%;
    width: 7px;
    height: 7px;
    animation: spin 2s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .save {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-top: 20px;
    background-color: #f5eceb;
    font-weight: 600;
    border: none;
    width: 100%;
    height: 40px;
    background-color: #e8355f;
    color: #feecf2;
    cursor: pointer;
    font-size: 13px;
    border-radius: 3px;
    justify-content: center;
    border: 1px solid;
    gap: 10px;
    padding: 0 10px;
  }
  .edit-btn {
    display: flex;
    border: none;
    background: none;
    color: #e8355f;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 30px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
  }
  .fonts {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .colors {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 30px;
  }
`;
