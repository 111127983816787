import React from "react";
import styled from "styled-components";
import SingleImage from "./singleImage";
import { motion } from "framer-motion";

const allMedia = ({ images, isSelector, IDselector, URLselector }) => {
  return (
    <MediaList>
      {images.map((image) => {
        return (
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.3 }}
            className="image-container"
          >
            <SingleImage
              image={image}
              isSelector={isSelector}
              IDselector={IDselector}
              URLselector={URLselector}
            />
          </motion.div>
        );
      })}
    </MediaList>
  );
};

export default allMedia;

const MediaList = styled.div`
  width: 90vw;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;

  .image-container {
    display: flex;
  }
`;
