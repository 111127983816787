import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Gallery from "../pages/gallery";
import WeddingAPI from "../api/wedding.api";
import { useDispatch } from "react-redux";
import { AiFillCamera } from "react-icons/ai";
import { MdImage } from "react-icons/md";
import BannerSelector from "./bannerSelector";

const CouplePrivate = ({ wedding, allImages }) => {
  const [selectGroomImage, setSelectGroomImage] = useState("");
  const [selectBrideImage, setSelectBrideImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  console.log(wedding);
  const [isGalleryOpenGroom, setIsGalleryOpenGroom] = useState(false);
  const [isGalleryOpenBride, setIsGalleryOpenBride] = useState(false);
  const [isFirstRenderGroom, setIsFirstRenderGroom] = useState(true);
  const [isFirstRenderBride, setIsFirstRenderBride] = useState(true);

  const [groomNameTemp, setGroomNameTemp] = useState(wedding.groomName);
  const [brideNameTemp, setBrideNameTemp] = useState(wedding.brideName);
  const [story, setStory] = useState(wedding.story);

  const dispatch = useDispatch();

  const toggleEdit = () => {
    if (isGalleryOpenGroom) {
      toggleGroomGallery();
    }
    if (isGalleryOpenBride) {
      toggleBrideGallery();
    }
    setIsEditing((prev) => !prev);
  };

  const toggleGroomGallery = () => {
    setIsGalleryOpenGroom((prev) => !prev);
  };

  const toggleBrideGallery = () => {
    setIsGalleryOpenBride((prev) => !prev);
  };

  useEffect(() => {
    if (isFirstRenderGroom) {
      setIsFirstRenderGroom(false);
      return;
    }
    setIsLoading(true);
    const data = {
      groomPictureId: selectGroomImage,
    };
    WeddingAPI.updateWedding(
      dispatch,
      wedding._id,
      setIsLoading,
      data,
      setIsGalleryOpenGroom
    );
  }, [selectGroomImage]);

  useEffect(() => {
    if (isFirstRenderBride) {
      setIsFirstRenderBride(false);
      return;
    }
    setIsLoading(true);
    const data = {
      bridePictureId: selectBrideImage,
    };
    WeddingAPI.updateWedding(
      dispatch,
      wedding._id,
      setIsLoading,
      data,
      setIsGalleryOpenBride
    );
  }, [selectBrideImage]);

  const saveTexts = () => {
    setIsLoading(true);
    const data = {
      groomName: groomNameTemp,
      brideName: brideNameTemp,
      story: story,
    };
    WeddingAPI.updateWedding(dispatch, wedding._id, setIsLoading, data);
    toggleEdit();
  };

  let textarea = document.getElementById("autoresizing");

  if (textarea) {
    textarea.addEventListener("input", autoResize, false);
  }

  function autoResize() {
    this.style.height = "auto";
    this.style.height = this.scrollHeight + "px";
  }

  return (
    <Couple>
      <div className="edit-container">
        <p className="title"> The Couple</p>
        {isEditing ? (
          <button className="edit-btn" onClick={toggleEdit}>
            Close
          </button>
        ) : (
          <button className="edit-btn" onClick={toggleEdit}>
            Edit
          </button>
        )}
      </div>
      <div className="couple-details">
        {wedding.groomPicture[0] ? (
          <img
            className="img"
            src={wedding.groomPicture[0].mediaURL}
            alt=""
          ></img>
        ) : (
          <div className="img-container" alt="" onClick={toggleGroomGallery}>
            <AiFillCamera />
          </div>
        )}
        <div className="couple-nav">
          <div className="edit-cont">
            {isEditing ? (
              <input
                type="text"
                value={groomNameTemp}
                onChange={(e) => setGroomNameTemp(e.target.value)}
                className="edit-name"
              />
            ) : (
              <p className="name">{wedding.groomName}</p>
            )}

            {isEditing && (
              <button className="select-img" onClick={toggleGroomGallery}>
                <MdImage />
              </button>
            )}
          </div>
        </div>
      </div>
      {isGalleryOpenGroom && (
        <Gallery isSelector={true} IDselector={setSelectGroomImage} />
      )}

      <div className="couple-details">
        {wedding.bridePicture[0] ? (
          <img
            className="img"
            src={wedding.bridePicture[0].mediaURL}
            alt=""
          ></img>
        ) : (
          <div className="img-container" alt="" onClick={toggleBrideGallery}>
            <AiFillCamera />
          </div>
        )}
        <div className="couple-nav">
          <div className="edit-cont">
            {isEditing ? (
              <input
                type="text"
                value={brideNameTemp}
                onChange={(e) => setBrideNameTemp(e.target.value)}
                className="edit-name"
              />
            ) : (
              <p className="name">{wedding.brideName}</p>
            )}
            {isEditing && (
              <button className="select-img" onClick={toggleBrideGallery}>
                <MdImage />
              </button>
            )}
          </div>
        </div>
      </div>
      {isGalleryOpenBride && (
        <Gallery isSelector={true} IDselector={setSelectBrideImage} />
      )}
      <div className="story-container">
        <div className="edit-container">
          <p className="title"> Our Story</p>
        </div>

        {isEditing ? (
          <textarea
            id="autoresizing"
            type="text"
            value={story}
            onChange={(e) => setStory(e.target.value)}
            className="edit-story"
          />
        ) : (
          <p className="story">{wedding.story}</p>
        )}
      </div>

      <BannerSelector
        wedding={wedding}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
      {isEditing && (
        <button onClick={saveTexts} className="save-btn">
          Save
        </button>
      )}
    </Couple>
  );
};

export default CouplePrivate;

const Couple = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 90vw;
  margin: 0 auto;
  gap: 10px;

  .couple-details {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
  }

  .img {
    display: flex;
    width: 100px;
    max-height: 100%;
    border-radius: 7px;
    background-color: white;
  }
  .edit-name {
    border: none;
    background: none;
    font-weight: 600;
    font-size: 17px;
    width: 80%;
    padding-left: 5px;
    border-radius: 4px;
    border: 1px solid lightgrey;
    margin-bottom: 20px;
    margin-top: 4px;
    height: 30px;
  }

  .edit-story {
    border: none;
    background: none;
    width: 100%;
    font-weight: 500;
    border-radius: 4px;
    border: 1px solid lightgrey;
    margin-bottom: 20px;
    margin-top: 4px;
    height: auto;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    overflow: none;
    resize: vertical;
    font-size: 16px;
    padding: 7px;
  }
  .edit-story::-webkit-scrollbar {
    display: none;
  }
  .edit-name:focus {
    padding-left: 10px;
    /* font-weight: bold; */
  }

  .edit-story:focus {
    padding-left: 10px;
    font-weight: 600;
  }

  .edit-name:placeholder-shown {
    padding-left: 10px;
  }
  .img-container {
    display: flex;
    width: 100px;
    height: 100px;
    border-radius: 7px;
    background-color: white;
    border: 1px solid lightgery;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #8f5854;
    cursor: pointer;
  }

  .select-img {
    border: none;
    font-size: 23px;
    background: none;
    color: #e8355f;
    padding: 7px;
    border-radius: 3px;
    cursor: pointer;
    margin-right: auto;
  }

  .title {
    color: #ae7288;
    font-weight: 600;
    font-size: larger;
  }
  .story {
    margin: 0;
    padding: 7px;
  }
  .edit-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .name {
    font-weight: 600;
  }
  .save-btn {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin: 0 auto;
    font-weight: 600;
    border: none;
    width: 100px;
    height: 40px;
    background-color: #e8355f;
    color: #feecf2;
    cursor: pointer;
    border-radius: 3px;
    justify-content: center;
    border: 1px solid;
    gap: 10px;
  }
  .edit-cont {
    display: flex;
    flex-direction: column;
  }
  .edit-btn {
    display: flex;
    border: none;
    background: none;
    color: #e8355f;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 30px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
  }
`;
