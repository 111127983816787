import axios from "axios";
import { weddingActions } from "../store/weddingSlice";

axios.defaults.withCredentials = true;

// const BASE_URL = "http://localhost:8000/";
const BASE_URL = "https://api-wedink-9u29u.ondigitalocean.app/";

const fetchAllWeddings = async (dispatch, setIsLoading) => {
  try {
    const response = await axios.create().get(`${BASE_URL}weddings/`);
    if (response.data.success) {
      dispatch(weddingActions.setWeddings(response.data.weddings));
    }
  } catch (err) {
    console.log(err.message);
  } finally {
    setIsLoading(false);
  }
};

const fetchWedding = async (dispatch, setIsLoading, username) => {
  try {
    const response = await axios
      .create()
      .get(`${BASE_URL}weddings/${username}`);
    if (response.data.success) {
      dispatch(weddingActions.setCurrentWedding(response.data.data[0]));
    }
  } catch (err) {
    console.log(err.message);
  } finally {
    setIsLoading(false);
  }
};

const fetchInvitation = async (
  dispatch,
  setIsLoading,
  setIsNotSubscribed,
  username,
  invitationID
) => {
  try {
    const response = await axios
      .create()
      .get(`${BASE_URL}weddings/${username}/invite/${invitationID}`);
    if (response.data.success) {
      dispatch(weddingActions.setInvitation(response.data.data[0]));
    } else {
      setIsNotSubscribed(true);
    }
  } catch (err) {
    console.log(err.message);
  } finally {
    setIsLoading(false);
  }
};

const createWedding = async (setIsSuccess, setIsLoading, data) => {
  try {
    const response = await axios.create().post(`${BASE_URL}weddings/new`, data);
    if (response.data.success) {
      setIsSuccess(true);
    }
  } catch (err) {
    console.log(err.message);
    setIsSuccess(false);
  } finally {
    setIsLoading(false);
  }
};

const createInvite = async (setIsSuccess, setIsLoading, data, setID) => {
  try {
    const response = await axios
      .create()
      .post(`${BASE_URL}invitations/new`, data);
    if (response.data.success) {
      setIsSuccess(true);
      setID(response.data.invitation._id);
    }
  } catch (err) {
    console.log(err.message);
    setIsSuccess(false);
  } finally {
    setIsLoading(false);
  }
};

const updateWedding = async (
  dispatch,
  weddingID,
  setIsLoading,
  data,
  setIsOpen
) => {
  try {
    const response = await axios
      .create()
      .put(`${BASE_URL}weddings/${weddingID}/update`, data);
    if (response.data.success) {
      dispatch(weddingActions.refreshWedding());
      setIsOpen(false);
    }
  } catch (err) {
    console.log(err.message);
  } finally {
    setIsLoading(false);
  }
};

const WeddingAPI = {
  fetchAllWeddings,
  fetchWedding,
  createWedding,
  updateWedding,
  fetchInvitation,
  createInvite,
};

export default WeddingAPI;
