import React from "react";
import styled from "styled-components";
import Theme from "../pages/theme";
import Couple from "./couple";
import People from "./people";
import Shedule from "./shedule";
import Wishes from "./wishes";
import Header from "./invitationHeader";

const AppearancePreview = ({ theme, wedding }) => {
  return (
    <Theme theme={theme}>
      <Preview>
        <div className="preview-window">
          <Header wedding={wedding} />
          <Couple wedding={wedding} />
          <People wedding={wedding} />
          <Shedule wedding={wedding} />
          <Wishes wedding={wedding} />
        </div>
      </Preview>
    </Theme>
  );
};

export default AppearancePreview;

const Preview = styled.div`
  display: flex;
  flex-direction: column;
  height: 50vh;
  overflow: scroll;
  border-radius: 10px;
  box-shadow: 5px 5px 20px #585b70;
  .preview-window {
    background-color: ${(props) => props.theme.backgroundColor};
    color: ${(props) => props.theme.contentFontColor};
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;
