import React, { useState, useEffect } from "react";
import styled from "styled-components";
import WeddingForm from "../components/weddingForm";
import PeopleForm from "../components/peopleForm";

const WeddingCreator = () => {
  const [id, setId] = useState(0);
  const FORMS = [<WeddingForm setId={setId} />, <PeopleForm setId={setId} />];
  return <Creator>{FORMS[id]}</Creator>;
};

export default WeddingCreator;

const Creator = styled.div`
  display: flex;
  min-width: 100vw;
  min-height: 80vh;
  align-items: center;
  justify-content: center;
`;
