import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import successGif from "../media/success.gif";
import WeddingAPI from "../api/wedding.api";

const WeddingForm = ({ setId }) => {
  const [groom, setGroom] = useState("");
  const [bride, setBride] = useState("");
  const [username, setUsername] = useState("");
  const [story, setStory] = useState("");

  const [isBasicsCompleted, setIsBasicsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isSuccess, setIsSuccess] = useState(false);

  const navigate = useNavigate();

  const moveNext = () => {
    if ((groom != "") & (bride != "") & (username != "")) {
      setIsBasicsCompleted(true);
    }
  };

  const moveBack = () => {
    setIsBasicsCompleted(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const data = {
      username: username,
      groomName: groom,
      brideName: bride,
      story: story,
    };
    WeddingAPI.createWedding(setIsSuccess, setIsLoading, data);
  };

  const toWedding = () => {
    navigate(`/${username}/admin`);
  };
  return (
    <Creator>
      {!isSuccess ? (
        <>
          <p className="title">Create your wedding</p>
          <form className="wedding-form" onSubmit={handleSubmit}>
            {!isBasicsCompleted ? (
              <div>
                <input
                  type="text"
                  value={groom}
                  onChange={(e) => setGroom(e.target.value)}
                  placeholder="Groom Name"
                  className="input"
                />

                <input
                  type="text"
                  value={bride}
                  onChange={(e) => setBride(e.target.value)}
                  placeholder="Bride Name"
                  className="input"
                />

                <input
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Give a Username"
                  className="input"
                />
              </div>
            ) : (
              <input
                type="text"
                value={story}
                onChange={(e) => setStory(e.target.value)}
                className="input"
                placeholder="Share your story..."
              />
            )}
          </form>

          {!isBasicsCompleted ? (
            <button type="button" className="nav-btn" onClick={moveNext}>
              Next
            </button>
          ) : (
            <div className="nav-btn-container">
              <button type="button" className="nav-btn" onClick={moveBack}>
                Back
              </button>

              <button type="submit" className="nav-btn" onClick={handleSubmit}>
                Create
              </button>
            </div>
          )}
        </>
      ) : (
        <div className="success-page">
          <p className="title">{`Congratulations 🎉 ${groom} and ${bride}`}</p>
          <img src={successGif} alt="" className="gif" />
          <p className="title">
            {" "}
            Your website is live, start customizing as you wish
          </p>
          <div className="nav-btn-container">
            <button className="nav-btn secondary" onClick={toWedding}>
              Customize my site
            </button>
          </div>
        </div>
      )}
    </Creator>
  );
};

export default WeddingForm;

const Creator = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 90vw;

  .wedding-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .input {
    min-width: 100%;
    background: none;
    border: none;
    border-bottom: 1px solid;
    height: 40px;
  }
  .gif {
    width: 50%;
  }
  .input:focus {
    padding-left: 10px;
    font-weight: bold;
  }

  .input:placeholder-shown {
    padding-left: 10px;
  }
  .title {
    font-weight: bold;
    margin: 0 auto;
    font-size: larger;
  }
  .success-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .nav-btn-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;
  }
  .nav-btn {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-top: 3px;
    font-weight: 600;
    border: none;
    width: 100%;
    height: 40px;
    background-color: #e8355f;
    color: #feecf2;
    cursor: pointer;
    border-radius: 3px;
    justify-content: center;
    border: 1px solid;
    gap: 10px;
  }
`;
