import React from "react";
import styled from "styled-components";

const singleWish = ({ data }) => {
  return (
    <Wish>
      <div className="wish-details">
        <p className="content">{data.wish}</p>
        <p className="name">{data.guestName}</p>
      </div>
    </Wish>
  );
};

export default singleWish;

const Wish = styled.div`
  display: flex;
  width: 90vw;
  margin: 0 auto;
  padding: 0 3px;

  border: 1px solid ${(props) => props.theme.titleFontColor};

  .wish-details {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    border-radius: 7px;
    justify-content: space-between;
    font-family: ${(props) => props.theme.contentFontFamily};
    color: ${(props) => props.theme.contentFontColor};
  }

  .content {
    color: ${(props) => props.theme.contentFontColor};
    font-size: 14px;
    font-family: ${(props) => props.theme.contentFontFamily};
  }

  .name {
    font-size: 12px;
    opacity: 40%;
    font-weight: bold;
    font-family: ${(props) => props.theme.contentFontFamily};
    color: ${(props) => props.theme.contentFontColor};
  }
`;
