import React, { useState } from "react";
import styled from "styled-components";
import { AiFillHeart } from "react-icons/ai";
import { GiButtonFinger, GiHamburgerMenu } from "react-icons/gi";
import { RiCloseFill } from "react-icons/ri";
import RazorpayAPI from "../api/razorpay.api";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const WeddingPrivateNav = ({ wedding, setTab }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const switchTab = (e) => {
    toggleMenu();
    setTab(e.target.value);
  };
  return (
    <Header>
      <div className="wedding-details" onClick={() => setTab("0")}>
        <p className="title">
          {wedding.groomName} <AiFillHeart />
          {wedding.brideName}
        </p>
        <p className="username">@{wedding.username}</p>
      </div>

      <button className="menu-btn" onClick={toggleMenu}>
        <GiHamburgerMenu />
      </button>

      {isMenuOpen && (
        <motion.div
          className="menu"
          initial={{ opacity: 0, scale: 0.8, x: 200 }}
          whileInView={{ opacity: 1, scale: 1, x: 0 }}
          transition={{ delay: 0.2 }}
          exit={{ opacity: 0, scale: 0.8, x: 200 }}
        >
          <button className="close-btn" onClick={toggleMenu}>
            <RiCloseFill />
          </button>
          <button className="menu-item" value="0" onClick={switchTab}>
            Couple
          </button>
          <button className="menu-item" value="1" onClick={switchTab}>
            People
          </button>
          <button className="menu-item" value="2" onClick={switchTab}>
            Events
          </button>
          <button className="menu-item" value="3" onClick={switchTab}>
            Wishes
          </button>
          <button className="menu-item" value="4" onClick={switchTab}>
            Appearance
          </button>
          <button className="menu-item" value="5" onClick={switchTab}>
            Invitations
          </button>

          {!wedding.hasSubscribed && (
            <a
              href={`https://thewedink.in/${wedding.username}`}
              className="menu-item site"
              target="_blank"
              rel="noopener noreferrer"
            >
              Preview
            </a>
          )}
          {!wedding.hasSubscribed ? (
            <button className="menu-item site" value="6" onClick={switchTab}>
              Buy
            </button>
          ) : (
            <a
              href={`https://thewedink.in/${wedding.username}`}
              className="menu-item site"
              target="_blank"
              rel="noopener noreferrer"
            >
              View Site
            </a>
          )}
          <br></br>
        </motion.div>
      )}
    </Header>
  );
};

export default WeddingPrivateNav;

const Header = styled.div`
  display: flex;
  margin: 10px auto;
  flex-direction: row;
  align-items: center;
  width: 90vw;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid lightgrey;

  .title {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: larger;
    gap: 10px;
    margin: 0;
  }
  .nav-buttons {
    display: flex;
    flex-direction: column-reverse;
    gap: 5px;
  }
  .wedding-details {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  .username {
    margin: 0;
    font-size: 13px;
  }

  .nav-btn {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-top: 3px;
    background-color: #a885ab;
    font-weight: 600;
    border: none;
    width: 100px;
    height: 40px;
    color: #80433e;
    cursor: pointer;
    border-radius: 3px;
    justify-content: center;
    border: 1px solid;
    gap: 10px;
  }

  .menu-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    text-decoration: none;
  }

  .menu {
    background-color: #ae7288;
    display: flex;
    flex-direction: column;
    gap: 30px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .menu-item {
    color: #feecf2;
    background: none;
    border: none;
    font-weight: 600;
    font-size: 20px;
    cursor: pointer;
  }

  .close-btn {
    cursor: pointer;
    font-size: 25px;
    border: none;
    background: none;
    color: #f5eceb;
  }

  .site {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-top: 3px;
    background-color: #f5eceb;
    font-weight: 600;
    border: none;
    width: 150px;
    height: 40px;
    color: #ae7288;
    background-color: #feecf2;
    cursor: pointer;
    font-size: 15px;
    border-radius: 3px;
    justify-content: center;
    gap: 10px;
    text-decoration: none;
  }
`;
