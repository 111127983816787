import React from "react";
import SingleEvent from "./singleEvent";

const allEvents = ({ events }) => {
  return (
    <div>
      {events.map((event) => {
        return <SingleEvent event={event} />;
      })}
    </div>
  );
};

export default allEvents;
