import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import PeopleAPI from "../api/people.api";
import Gallery from "../pages/gallery";

const PersonPrivate = ({ person, weddingID }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [personName, setPersonName] = useState(person.personName);
  const [pictureID, setPictureID] = useState("");
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [role, setRole] = useState(person.role);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }
    setIsLoading(true);
    const data = {
      pictureID,
      weddingID,
    };
    PeopleAPI.updatePeople(
      dispatch,
      person._id,
      setIsLoading,
      data,
    );
  }, [pictureID]);

  const saveUpdate = () => {
    setIsLoading(true);
    const data = {
      personName,
      role,
    };
    PeopleAPI.updatePeople(
      dispatch,
      person._id,
      setIsLoading,
      data,
      setIsEditing
    );
  };

  const toggleGallery = () => {
    isEditing && setIsGalleryOpen((prev) => !prev);
  };
  const toggleEdit = () => {
    setIsEditing((prev) => !prev);
  };

  return (
    <Person>
      <div className="details">
        {person.PeoplePictures[0] && (
          <img
            src={person.PeoplePictures[0].mediaURL}
            alt=""
            className="person-image"
            onClick={toggleGallery}
          />
        )}
        <form className="person-detail">
          {!isEditing ? (
            <p className="name">{person.personName}</p>
          ) : (
            <input
              className="input"
              value={personName}
              placeholder="name"
              onChange={(e) => setPersonName(e.target.value)}
            />
          )}
          {!isEditing ? (
            <p className="role">{person.role}</p>
          ) : (
            <input
              className="input"
              value={role}
              placeholder="name"
              onChange={(e) => setRole(e.target.value)}
            />
          )}
          {!isEditing ? (
            <button type="button" className="edit-btn" onClick={toggleEdit}>
              Edit
            </button>
          ) : (
            <button type="button" className="edit-btn" onClick={saveUpdate}>
              Save
            </button>
          )}
        </form>
      </div>
      {isGalleryOpen && <Gallery isSelector={true} IDselector={setPictureID} />}
    </Person>
  );
};

export default PersonPrivate;

const Person = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .details {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  .person-image {
    width: 100px;
    max-height: 100%;
    border-radius: 5px;
    cursor: pointer;
  }

  .input {
    border-radius: 7px;
    height: 30px;
    width: 100%;
    border: 2px solid black;
    padding-left: 10px;
    font-weight: bold;
    background-color: #feecf2;
  }
  .input:focus {
    padding-left: 10px;
    font-weight: bold;
  }

  .input:placeholder-shown {
    padding-left: 10px;
  }

  .name {
    font-weight: 600;
    margin: 0;
    margin-top: 5px;
  }
  .person-detail {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .role {
    font-size: 14px;
    margin: 0;
  }

  .edit-btn {
    display: flex;
    margin-top: auto;
    margin-bottom: 4px;
    margin-right: 0;
    border: none;
    background: none;
    font-weight: 600;
    color: #e8355f;
    cursor: pointer;
  }
`;
