import React from "react";
import styled from "styled-components";
import { RiHeart3Fill } from "react-icons/ri";

const invitationHeader = ({ wedding }) => {
  return (
    <Header>
      <p className="names">
        {wedding.groomName} <RiHeart3Fill /> {wedding.brideName}
      </p>
    </Header>
  );
};

export default invitationHeader;

const Header = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  position: sticky;
  top: 0;
  margin-top: 0;
  left: 0;
  background-color: ${(props) => props.theme.backgroundColor};
  .names {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    font-family: ${(props) => props.theme.titleFontFamily};
    color: ${(props) => props.theme.titleFontColor};
    font-size: 25px;
    font-weight: 600;
    width: 100%;
  }
`;
