import React from "react";
import styled from "styled-components";

const SingleEvent = ({ event }) => {
  const dateTime = new Date(event.eventDateTime);
  const date = `
    ${(dateTime + "").substring(0, 3)}, ${(dateTime + "").substring(4, 16)}`;

  return (
    <EventContainer>
      <div className="name-date">
        <p className="name">{event.eventName}</p>
        <p className="date">
          {event.eventDateTime.length > 0 && <p className="date">{date}</p>}
        </p>
      </div>
      <p className="desc">{event.eventDesc}</p>
      {event.locationLink.length > 0 ? (
        <a className="location link" href={event.locationLink}>
          {event.locationText}
        </a>
      ) : (
        <a className="location">{event.locationText}</a>
      )}
    </EventContainer>
  );
};

export default SingleEvent;

const EventContainer = styled.div`
  display: flex;
  flex-direction: column;
  .name {
    font-size: large;
    font-weight: 600;
  }

  .date {
    font-weight: 600;
    font-size: 14px;
  }
  .desc {
    font-size: 14px;
    margin: 0;
  }
  .name-date {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .location {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-top: 3px;
    font-weight: 600;
    font-size: small;
    color: black;
    height: 40px;
    cursor: pointer;
    border-radius: 3px;
    border: none;
    margin-top: 10px;
    gap: 10px;
    text-decoration: none;
  }

  .link {
    color: #e8355f;
  }
`;
