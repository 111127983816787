import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const HomeCard = ({ img, title, caption }) => {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    return navigate("/register");
  };
  return (
    <Card>
      <div className="img-card">
        <img src={img} alt="" className="img" />
        <div className="cta-item">
          <h3 className="title">{title}</h3>
        </div>
      </div>
      <button className="get-started" onClick={handleGetStarted}>
        Get started
      </button>
      <a
        className="get-started secondary"
        href={`https://thewedink.in/rithishwedspragathi`}
        target="_blank"
        rel="noopener noreferrer"
      >
        View Demo
      </a>
    </Card>
  );
};

export default HomeCard;

const Card = styled.div`
  .img-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
  }

  .img {
    display: flex;
    width: 90vw;
    border-radius: 5px;
  }
  .title {
    font-size: 35px;
  }
  .get-started {
    display: flex;
    cursor: pointer;
    align-items: center;
    font-weight: 600;
    border: none;
    width: 100%;
    height: 40px;
    background-color: #e8355f;
    color: #feecf2;
    cursor: pointer;
    border-radius: 3px;
    justify-content: center;
    border: 1px solid;
    gap: 10px;
    margin: 10px auto;
    text-decoration: none;
    font-size: 15px;
  }
  .secondary {
    color: #e8355f;
    background-color: #feecf2;
  }
`;
