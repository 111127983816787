import React, { useState } from "react";
import styled from "styled-components";

const Selector = ({ setList, value, labelField }) => {
  const [isSelected, setIsSelected] = useState(false);
  const toggleSelect = () => {
    setIsSelected((prev) => !prev);
    if (!isSelected) {
      setList((prev) => [...prev, value._id]);
    } else {
      setList((prev) => prev.filter((e) => e != value._id));
    }
  };
  return (
    <SelectorContainer>
      <div className="value-container">
        <p
          className={isSelected ? "name selected" : "name"}
          onClick={toggleSelect}
        >
          {value[labelField]}
        </p>
      </div>
    </SelectorContainer>
  );
};

export default Selector;

const SelectorContainer = styled.div`
  border: 1px solid lightgrey;
  margin: 4px 0;
  border-radius: 5px;
  padding-left: 10px;
  .selected {
    font-size: 15px;
    text-decoration: line-through;
    opacity: 80%;
  }
  .name {
    margin: 0;
    margin: 7px 0;
    font-weight: 500;
    color: #000;
  }
`;
