const fontOptions = [
  "Montsterrat",
  "Rubik",
  "Qwitcher Grypen",
  "Oswald",
  "Neucha",
  "Roboto Mono",
  "Shadows Into Light",
  "Alumni Sans Pinstripe",
  "Edu SA Beginner",
  "Alumni Sans Pinstripe",
  "Amatic SC",
  "Comfortaa",
  "Edu SA Beginner",
  "Fuzzy Bubbles",
  "Inconsolata",
  "Inter",
  "Patrick Hand",
  "Special Elite",
];

const themes = [
  {
    name: "Deep Orange",
    backgroundColor: "#000000",
    buttonBackgroundColor: "#e79470",
    buttonTextColor: "#000000",
    contentFontColor: "#e3d5d5",
    contentFontFamily: "Roboto Mono",
    titleFontColor: "#e7b184",
    titleFontFamily: "Alumni Sans Pinstripe",
  },

  {
    name: "Suttle Red",
    backgroundColor: "#fff4f4",
    buttonBackgroundColor: "#cd5252",
    buttonTextColor: "#ffe8e8",
    contentFontColor: "#5e3333",
    contentFontFamily: "Rubik",
    titleFontColor: "#763a3a",
    titleFontFamily: "Shadows Into Light",
  },
  {
    name: "The Olive",
    backgroundColor: "#eff2f2",
    buttonBackgroundColor: "#314947",
    buttonTextColor: "#d6e3e6",
    contentFontColor: "#337a74",
    contentFontFamily: "Inter",
    titleFontColor: "#3c3c4f",
    titleFontFamily: "Special Elite",
  },
  {
    name: "Dark Pink",
    backgroundColor: "#260d1a",
    buttonBackgroundColor: "#e0abbb",
    buttonTextColor: "#3b0a1f",
    contentFontColor: "#dcc9d4",
    contentFontFamily: "Roboto Mono",
    titleFontColor: "#eab9ca",
    titleFontFamily: "Amatic SC",
  },
  {
    name: "The Romance",
    backgroundColor: "#eee0e7",
    buttonBackgroundColor: "#953350",
    buttonTextColor: "#efd7e2",
    contentFontColor: "#4f2e41",
    contentFontFamily: "Roboto Mono",
    titleFontColor: "#843752",
    titleFontFamily: "Patrick Hand",
  },
];
const Data = {
  fontOptions,
  themes,
};

export default Data;
