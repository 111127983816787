import React, { useState } from "react";
import styled from "styled-components";
import AppearanceAPI from "../api/appearance.api";
import { useDispatch } from "react-redux";

const SingleTheme = ({ theme, themeID }) => {
  const [isSelected, setIsSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggleSelect = () => {
    setIsSelected((prev) => !prev);
  };

  const dispatch = useDispatch();

  const changeTheme = (e) => {
    e.preventDefault();
    setIsLoading(true);
    AppearanceAPI.updateAppearance(
      dispatch,
      themeID,
      setIsLoading,
      theme,
      toggleSelect
    );
  };
  return (
    <Theme>
      <p
        className={isSelected ? "theme selected" : "theme"}
        style={{
          background: theme.backgroundColor,
          color: theme.contentFontColor,
        }}
        onClick={toggleSelect}
      >
        <p style={{ fontFamily: theme.titleFontFamily }}>{theme.name}</p>
      </p>
      {isSelected && (
        <button className="select-btn" onClick={changeTheme}>
          {isLoading ? "Saving..." : "Select"}
        </button>
      )}
    </Theme>
  );
};

export default SingleTheme;
const Theme = styled.div`
  .select-btn {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-top: 3px;
    font-weight: 600;
    border: none;
    width: 150px;
    height: 40px;
    background-color: #e8355f;
    color: #feecf2;
    cursor: pointer;
    border-radius: 3px;
    justify-content: center;
    border: px solid;
    gap: 10px;
  }
`;
