import React, { useState, useEffect } from "react";
import styled from "styled-components";
import WeddingAPI from "../api/wedding.api";
import { useDispatch } from "react-redux";
import { AiFillCamera } from "react-icons/ai";
import Gallery from "../pages/gallery";

const BannerSelector = ({ wedding }) => {
  const [image, setImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const toggleGallery = () => {
    setIsGalleryOpen((prev) => !prev);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }
    setIsLoading(true);
    const data = {
      couplePictureId: image,
    };
    WeddingAPI.updateWedding(
      dispatch,
      wedding._id,
      setIsLoading,
      data,
      setIsGalleryOpen
    );
  }, [image]);
  return (
    <Selector>
      <div className="edit-container">
        <p className="title"> Banner</p>

        <button className="image-banner-btn" onClick={toggleGallery}>
          <AiFillCamera />
        </button>
      </div>
      {wedding.couplePicture[0] ? (
        <img
          className="img-banner"
          src={wedding.couplePicture[0].mediaURL}
          alt=""
          onClick={toggleGallery}
        ></img>
      ) : (
        <div className="banner-btn" alt="" onClick={toggleGallery}>
          Add Banner <AiFillCamera />
        </div>
      )}
      {isGalleryOpen && <Gallery isSelector={true} IDselector={setImage} />}
    </Selector>
  );
};

export default BannerSelector;

const Selector = styled.div`
  width: 90vw;
  .img-banner {
    width: 90vw;
    margin: 0 auto;
    box-shadow: 5px 5px 20px #585b70;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  .image-banner-btn {
    display: flex;
    background: none;
    border: none;
    font-size: 20px;
    color: #e8355f;
  }
  .banner-btn {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin: 0 auto;
    font-weight: 600;
    border: none;
    width: 100%;
    height: 40px;
    background-color: #e8355f;
    color: #feecf2;
    cursor: pointer;
    border-radius: 3px;
    justify-content: center;
    border: 1px solid;
    gap: 10px;
  }
`;
