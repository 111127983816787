import React, { useState } from "react";
import styled from "styled-components";
import AllPeople from "./allPeoplesList";
import PeopleForm from "../components/peopleForm";

const PeoplePrivate = ({ people, weddingID }) => {
  const [isAddingPeople, setIsAddingPeople] = useState(false);

  const toggleAdding = () => {
    setIsAddingPeople((prev) => !prev);
  };

  return (
    <PeopleContainer>
      <div className="title-nav">
        <p className="title">People</p>
        <button className="add-ppl-btn" onClick={toggleAdding}>
          {isAddingPeople ? "Close" : "Add Person"}
        </button>
      </div>
      {isAddingPeople && (
        <PeopleForm weddingID={weddingID} toggleAdding={toggleAdding} />
      )}
      {Object.keys(people).length === 0 && (
        <button className="empty-btn" onClick={toggleAdding}>
          Add People
        </button>
      )}
      <AllPeople people={people} weddingID={weddingID} />
    </PeopleContainer>
  );
};

export default PeoplePrivate;

const PeopleContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 90vw;
  min-height: 70vh;
  margin: 0 auto;
  gap: 10px;
  .title {
    font-weight: 600;
    color: #ae7288;
    font-size: larger;
  }

  .title-nav {
    display: flex;
    justify-content: space-between;
  }

  .add-ppl-btn {
    border: none;
    background: none;
    font-weight: 600;
    color: #e8355f;
  }

  .empty-btn {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-top: 3px;
    font-weight: 600;
    border: none;
    width: 150px;
    height: 40px;
    background-color: #e8355f;
    color: #feecf2;
    cursor: pointer;
    border-radius: 3px;
    justify-content: center;
    border: 1px solid;
    gap: 10px;
    margin: auto;
  }
`;
