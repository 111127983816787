import React, { useState } from "react";
import styled from "styled-components";
import WishForm from "./addWishForm";

const CustomInvite = ({ wedding }) => {
  const [isFormOpen, setIsFormOpen] = useState(false);

  const toggleForm = () => {
    setIsFormOpen((prev) => !prev);
  };

  console.log(wedding.invitations);

  return (
    <Invite>
      <div className="invite-bar">
        <div className="wish-container">
          <p className="invite-name">
            {"Welcome" + " " + wedding.invitations[0].guestName}
          </p>
          <button className="show-form-btn" onClick={toggleForm}>
            {isFormOpen ? "Close" : "Wish us"}
          </button>
        </div>
        {isFormOpen && (
          <WishForm
            wedding={wedding}
            setIsFormOpen={setIsFormOpen}
            toggleForm={toggleForm}
          />
        )}
      </div>
    </Invite>
  );
};

export default CustomInvite;

const Invite = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  background: transparent;
  /* height: 200px; */
  margin: 0 auto;
  position: fixed;
  bottom: 0;

  .wish-container {
    display: flex;
    justify-content: space-between;
    margin: 0 10px;
    align-items: center;
  }

  .invite-name {
    margin: 0;
  }

  .show-form-btn {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-top: 3px;
    font-weight: 600;
    border: none;
    width: max-content;
    height: 40px;
    background-color: ${(props) => props.theme.backgroundColor};
    color: ${(props) => props.theme.titleFontColor};
    cursor: pointer;
    font-family: ${(props) => props.theme.contentFontFamily};
    border-radius: 3px;
    justify-content: center;
    gap: 10px;
    padding: 0 20px;
  }
  .invite-bar {
    display: flex;
    flex-direction: column;
    width: 90vw;
    margin: 10px auto;
    border-radius: 7px;
    background-color: ${(props) => props.theme.buttonBackgroundColor};
    padding: 6px;
    justify-content: center;

    font-weight: 900;
    p {
      font-family: ${(props) => props.theme.titleFontFamily};
      font-size: 19px;
      color: ${(props) => props.theme.buttonTextColor};
    }
  }
`;
