import React from "react";
import styled from "styled-components";
import Person from "./personPrivate";

const allPeoplesList = ({ people, weddingID}) => {
  return (
    <AllPeopleContainer>
      {people.map((person) => {
        return <Person person={person} weddingID={weddingID}/>;
      })}
    </AllPeopleContainer>
  );
};

export default allPeoplesList;

const AllPeopleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
