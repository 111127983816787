import React, { useState } from "react";
import styled from "styled-components";
import SingleTheme from "./singleTheme";
import Data from "./_data";

const ThemeSelector = ({ theme }) => {
  return (
    <Selector>
      <p className="title">Theme</p>
      <div className="themes-grid">
        {Data.themes.map((defaultTheme) => {
          return <SingleTheme theme={defaultTheme} themeID={theme._id} />;
        })}
      </div>
    </Selector>
  );
};

export default ThemeSelector;

const Selector = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 90vw;

  .themes-grid {
    display: grid;
    width: 100%;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    margin: 0 auto;
  }
  .theme {
    border: 2px solid black;
    height: 100px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    padding: 5px;
    font-weight: 600;
    cursor: pointer;
  }

  .selected {
    box-shadow: 5px 5px 20px #585b70;
  }
`;
