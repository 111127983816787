import React from "react";
import styled from "styled-components";
import CaricatureImg from "../media/caricature.jpg";
import { motion } from "framer-motion";

const Couple = ({ wedding, theme }) => {
  return (
    <CoupleContainer>
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.3 }}
      >
        {wedding.couplePicture[0] && (
          <img
            src={wedding.couplePicture[0].mediaURL}
            alt=""
            className="couple-banner"
          />
        )}
      </motion.div>

      <div className="text-content">
        <p className="title-inv underline">The Couple</p>
        <p className="our-story">{wedding.story}</p>
      </div>

      <div className="couple-details">
        <div className="person-details">
          <p className="title-inv">{wedding.groomName}</p>
          {wedding.groomPicture[0] && (
            <img
              src={wedding.groomPicture[0].mediaURL}
              alt=""
              className="avatar"
            />
          )}
        </div>
        <div className="person-details">
          <p className="title-inv ">{wedding.brideName}</p>
          {wedding.bridePicture[0] && (
            <img
              src={wedding.bridePicture[0].mediaURL}
              alt=""
              className="avatar"
            />
          )}
        </div>
      </div>
    </CoupleContainer>
  );
};

export default Couple;

const CoupleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0;
  padding: 10px 0;
  margin-top: 0;

  .title-inv {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    font-family: ${(props) => props.theme.titleFontFamily};
    color: ${(props) => props.theme.titleFontColor};
    font-weight: 800;
  }
  .underline {
    text-decoration: underline;
    text-underline-offset: 5px;
    color: ${(props) => props.theme.titleFontColor};
    font-family: ${(props) => props.theme.titleFontFamily};
  }
  .couple-banner {
    display: flex;
    height: 90vh;
    margin: 0 auto;
    border-radius: 7px;
    box-shadow: 5px 5px 20px ${(props) => props.theme.titleFontColor}50!important;
    border: 2px solid ${(props) => props.theme.titleFontColor};
    /* padding: 5px; */
    margin-top: 10px;
  }
  .our-story {
    width: 50%;
    margin: 0 auto;
    text-align: center;
    color: ${(props) => props.theme.contentFontColor};
    font-family: ${(props) => props.theme.contentFontFamily};
  }

  .couple-details {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  .person {
    display: flex;
    flex-direction: column;
  }

  .avatar {
    width: 200px;
    border-radius: 45px;
  }

  @media only screen and (max-width: 600px) {
    & {
      min-width: 90vw;
      max-width: 90vw;
      margin: 0 auto;
    }

    .couple-banner {
      display: flex;
      width: 100%;
      height: 100%;
      height: none;
      margin: 0 auto;
      border-radius: 7px;
      /* box-shadow: 5px 5px 20px #585b70; */
    }

    .our-story {
      width: 100%;
    }
    .avatar {
      width: 30vw;
      border-radius: 45px;
    }
    .couple-details {
      min-width: 100%;
    }
  }
`;
