import axios from "axios";
import { weddingActions } from "../store/weddingSlice";

axios.defaults.withCredentials = true;

// const BASE_URL = "http://localhost:8000/";
const BASE_URL = "https://api-wedink-9u29u.ondigitalocean.app/";

const updateGuest = async (dispatch, guestID, setIsLoading, data) => {
  try {
    const response = await axios
      .create()
      .put(`${BASE_URL}guests/${guestID}/update`, data);
    if (response.data.success) {
      dispatch(weddingActions.refreshWedding());
    }
  } catch (err) {
    console.log(err.message);
  } finally {
    setIsLoading(false);
  }
};
const newGuest = async (dispatch, setIsLoading, data, toggleForm) => {
  try {
    const response = await axios.create().post(`${BASE_URL}guests/new`, data);
    if (response.data.success) {
      dispatch(weddingActions.refreshWedding());
      toggleForm();
    }
  } catch (err) {
    console.log(err.message);
  } finally {
    setIsLoading(false);
  }
};

const GuestAPI = {
  updateGuest,
  newGuest,
};

export default GuestAPI;
