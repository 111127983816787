import React from "react";
import styled from "styled-components";

const optionInput = ({
  options,
  defaultValue,
  curTheme,
  label,
  value,
  name,
  isEditing,
  onChange,
}) => {
  return (
    <Selector>
      <label className="label">{label}</label>
      {isEditing ? (
        <select
          className="selector"
          value={value}
          onChange={onChange}
          name={name}
        >
          <option className="input-option" selected hidden>
            {defaultValue}
          </option>
          {options.map((option) => {
            return <option className="input-option">{option}</option>;
          })}
        </select>
      ) : (
        <p className="selector">{value}</p>
      )}
    </Selector>
  );
};

export default optionInput;

const Selector = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
  gap: 5px;
  .selector {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-top: 3px;
    background-color: #f5eceb;
    font-weight: 600;
    border: none;
    height: 40px;
    background-color: #e8355f;
    color: #feecf2;
    cursor: pointer;
    font-size: 13px;
    border-radius: 3px;
    border: 1px solid;
    gap: 10px;
    padding: 0 10px;
  }
  .label {
    font-weight: 600;
  }
`;
