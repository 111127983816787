import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Login from "./pages/login";
import Register from "./pages/register";

import Footer from "./components/footer";
import NavBar from "./components/navbar";

import SingleInvitation from "./pages/singleInvitation";
import Weddings from "./pages/weddings";
import SingleWedding from "./pages/singleWedding";
import WeddingCreator from "./pages/weddingCreator";
import Theme from "./pages/theme";
import Gallery from "./pages/gallery";

import "./App.css";
import { useEffect, useState } from "react";
import AccountsAPI from "./api/accounts.api";
import { useDispatch, useSelector } from "react-redux";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const dispatch = useDispatch();

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
  });

  useEffect(() => {
    AccountsAPI.getUser(setIsLoading, dispatch);
  }, []);

  if (isLoading) {
    return <></>;
  }
  return (
    <Router>
      <NavBar />
      <Routes>
        {isLoggedIn ? (
          <Route exact path="/" element={<Weddings />}></Route>
        ) : (
          <Route exact path="/" element={<Home />}></Route>
        )}

        <Route exact path="/login" element={<Login />}></Route>
        <Route exact path="/register" element={<Register />}></Route>
        <Route exact path="/gallery" element={<Gallery />}></Route>

        <Route exact path="/weddings" element={<Weddings />}></Route>
        <Route exact path="/weddings/new" element={<WeddingCreator />}></Route>

        <Route exact path="/:username" element={<SingleInvitation />}></Route>
        <Route
          exact
          path="/:username/admin"
          element={<SingleWedding />}
        ></Route>
        <Route
          exact
          path="/:username/:invitationID"
          element={<SingleInvitation />}
        ></Route>
      </Routes>
      {/* <Footer /> */}
    </Router>
  );
}

export default App;
