import React, { useState } from "react";
import styled from "styled-components";
import MediaAPI from "../api/media.api";
import { useDispatch } from "react-redux";

const UploadMedia = () => {
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  if (image) {
    console.log(image.images);
  }

  const onChangeFile = (e) => {
    setImage({ ["images"]: e.target.files[0] });
  };

  const handleUpload = (e) => {
    e.preventDefault();

    setIsLoading(true);
    MediaAPI.uploadMedia(dispatch, setIsLoading, image, setImage);
  };
  return (
    <Uploader>
      {image &&
        Object.keys(image).length > 0 &&
        (image.images ? (
          <img
            src={URL.createObjectURL(image.images)}
            alt=""
            className="preview"
          />
        ) : (
          <></>
        ))}
      <form className="form" onSubmit={handleUpload}>
        <input
          type="file"
          name="media"
          onChange={onChangeFile}
          placeholder=""
          className="image-input"
          id="image-input"
        ></input>

        {image && Object.keys(image).length > 0 ? (
          image.images ? (
            <div className="button-container">
              {isLoading ? (
                <div class="loader"></div>
              ) : (
                <label htmlFor="image-input" className="input-label">
                  Change
                </label>
              )}
              {!isLoading && (
                <button onSubmit={handleUpload} className="upload-btn">
                  Upload
                </button>
              )}
            </div>
          ) : (
            <label htmlFor="image-input" className="input-label">
              Upload Image
            </label>
          )
        ) : (
          <label htmlFor="image-input" className="input-label">
            Upload Image
          </label>
        )}
      </form>
    </Uploader>
  );
};

export default UploadMedia;

const Uploader = styled.div`
  display: flex;
  margin: 10px auto;
  flex-direction: column;
  gap: 15px;
  position: sticky;
  z-index: 100;

  align-items: center;
  width: 90vw;
  justify-content: center;

  .form {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    justify-content: center;
  }
  .file-name {
    display: flex;
    background-color: #f5eceb;
    color: #8f5854;
    align-items: center;
    justify-content: center;
    margin-top: 7px;
    padding: 2px 7px;
    border-radius: 3px;
    width: max-content;
    cursor: pointer;
  }
  .loader {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #8f5854; /* Blue */
    border-radius: 50%;
    width: 7px;
    height: 7px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .button-container {
    display: flex;
    gap: 10px;
  }
  .preview {
    display: flex;
    max-height: 100%;
    width: 400px;
    margin: auto;
    backdrop-filter: blur(20px);
    position: relative;
    image-rendering: optimizeQuality;
    border-radius: 7px;
    box-shadow: 5px 5px 20px #585b70;
  }

  .image-input {
    opacity: 0;
    width: 0.1px;
    height: 0.1px;
    position: absolute;
  }
  .input-label {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-top: 3px;
    background-color: #f5eceb;
    /* font-family: Shadows into light; */
    /* font-size: 25px; */
    font-weight: 600;
    border: none;
    width: 150px;
    height: 40px;
    background-color: #e8355f;
    color: #feecf2;
    cursor: pointer;
    font-size: 13px;
    border-radius: 3px;
    justify-content: center;
    border: 1px solid;
    gap: 10px;
  }
  .upload-btn {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-top: 3px;
    font-weight: 600;
    border: none;
    width: 150px;
    height: 40px;
    background-color: #e8355f;
    color: #feecf2;
    cursor: pointer;
    border-radius: 3px;
    justify-content: center;
    border: 1px solid;
    gap: 10px;
  }
  @media only screen and (max-width: 600px) {
    .preview {
      width: 80vw;
    }
  }
`;
