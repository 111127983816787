import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const Person = ({ data }) => {
  return (
    <PersonContainer>
      {Object.keys(data).length > 0 && (
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ delay: 0.3 }}
        >
          {data.PeoplePictures[0] && (
            <img
              src={data.PeoplePictures[0].mediaURL}
              alt=""
              className="person-picture"
            />
          )}
          <p className="person-name">{data.personName}</p>
          <p className="person-role">{data.role}</p>
        </motion.div>
      )}
    </PersonContainer>
  );
};

export default Person;

const PersonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  margin: 5px auto;
  justify-content: center;

  .person-name {
    margin-top: 7px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    font-family: ${(props) => props.theme.titleFontFamily};
    color: ${(props) => props.theme.titleFontColor};
    font-weight: 800;
  }

  .person-role {
    margin-top: 3px;
    margin-bottom: 0;
    text-align: center;
    font-size: 15px;
    font-family: ${(props) => props.theme.contentFontFamily};
  }

  .person-picture {
    display: flex;
    width: 500px;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 5px 5px 20px ${(props) => props.theme.titleFontColor};
    border: 2px solid ${(props) => props.theme.titleFontColor};
  }

  @media only screen and (max-width: 600px) {
    & {
      min-width: 90vw;
      max-width: 90vw;
      margin: 0 auto;
    }

    .person-picture {
      display: flex;
      width: 100%;
      height: 100%;
      height: none;
      margin: 0 auto;
      border-radius: 10px;
      box-shadow: 5px 5px 20px
        ${(props) => props.theme.titleFontColor}50!important;
      border: 2px solid ${(props) => props.theme.titleFontColor};
    }

    .avatar {
      width: 30vw;
      border-radius: 45px;
    }
    .couple-details {
      min-width: 100%;
    }
  }
`;
