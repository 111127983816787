import React from "react";
import styled from "styled-components";
import Logo from "../media/logo-light.png";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();

  const toHome = () => {
    document.body.style.background = "#feecf2";
    navigate("/");
  };
  return (
    <NavBarContainer id="navbar">
      <img src={Logo} alt="" className="logo" onClick={toHome} />
    </NavBarContainer>
  );
};

export default Navbar;

const NavBarContainer = styled.div`
  display: flex;
  width: 100vw;
  background-color: #ae7288;
  height: 50px;
  color: #8f5854;
  position: sticky;
  top: 0;
  margin-top: 0;
  left: 0;
  z-index: 1001;

  .logo {
    cursor: pointer;
    display: flex;
    margin: 5px auto;
  }
`;
