import { createSlice } from "@reduxjs/toolkit";

const weddingSLice = createSlice({
  name: "wedding",
  initialState: {
    allWeddings: [],
    currentWedding: {},
    isUpdated: false,
    invitation: [],
  },
  reducers: {
    setWeddings(state, action) {
      state.allWeddings = action.payload;
    },
    setInvitation(state, action) {
      state.invitation = action.payload;
    },
    setCurrentWedding(state, action) {
      state.currentWedding = action.payload;
    },
    refreshWedding(state) {
      state.isUpdated = !state.isUpdated;
    },
  },
});

export const weddingActions = weddingSLice.actions;

export default weddingSLice;
