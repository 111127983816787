import React, { useState } from "react";
import styled from "styled-components";
import Person from "./person";
import { MdArrowBack } from "react-icons/md";
import { MdArrowForward } from "react-icons/md";

const People = ({ wedding }) => {

  console.log(wedding)
  const [curPerson, setCurPerson] = useState(0);

  const nextPerson = () => {
    if (curPerson < Object.keys(wedding.people).length - 1) {
      setCurPerson((prev) => (prev += 1));
    }
  };
  const prevPerson = () => {
    if (curPerson > 0) {
      setCurPerson((prev) => (prev -= 1));
    }
  };
  console.log(wedding.people[curPerson]);

  return (
    <PeopleContainer>
      <div className="people-nav">
        <p className="nav" onClick={prevPerson}>
          <MdArrowBack />
        </p>
        <p className="title-inv underline">People</p>
        <p className="nav" onClick={nextPerson}>
          {" "}
          <MdArrowForward />
        </p>
      </div>
      {wedding.people[0] && <Person data={wedding.people[curPerson]} />}
    </PeopleContainer>
  );
};

export default People;

const PeopleContainer = styled.div`
  display: flex;
  flex-direction: column;
  .title-inv {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    color: ${(props) => props.theme.titleFontColor};
    font-family: ${(props) => props.theme.titleFontFamily};
    font-weight: 800;
  }
  .underline {
    text-decoration: underline;
    text-underline-offset: 5px;
    color: ${(props) => props.theme.titleFontColor};
    font-family: ${(props) => props.theme.titleFontFamily};
  }

  .people-nav {
    display: flex;
    flex-direction: row;
    width: 90vw;
    margin: 5px auto;
    justify-content: center;
    gap: 50px;
    align-items: center;
    color: ${(props) => props.theme.titleFontColor};
  }
  .nav {
    cursor: pointer;
    font-size: larger;
  }
`;
