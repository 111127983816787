import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Gallery from "../pages/gallery";
import { HiCamera } from "react-icons/hi";
import { useDispatch } from "react-redux";
import PeopleAPI from "../api/people.api";

const PeopleForm = ({ weddingID, toggleAdding }) => {
  const [personName, setPersonName] = useState("");
  const [role, setRole] = useState("");
  const [pictureID, setPictureID] = useState("");
  const [isGalleryOpen, setIsGalleryOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [imageUrl, setImageUrl] = useState("");

  const dispatch = useDispatch();

  const toggleGallery = () => {
    setIsGalleryOpen((prev) => !prev);
  };

  useEffect(() => {
    toggleGallery();
  }, [imageUrl]);
  const savePerson = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const data = {
      personName: personName,
      role: role,
      weddingID: weddingID,
      pictureID: pictureID,
    };
    PeopleAPI.addPerson(dispatch, setIsLoading, data);
    toggleGallery();
    toggleAdding();
  };

  return (
    <FormContainer>
      <div className="form-elements">
        {imageUrl.length > 0 ? (
          <img src={imageUrl} alt="" className="img" onClick={toggleGallery} />
        ) : (
          <button className="select-image" onClick={toggleGallery}>
            <HiCamera />
          </button>
        )}
        <form className="person-form">
          <input
            type="text"
            className="input"
            value={personName}
            onChange={(e) => setPersonName(e.target.value)}
            placeholder="Name"
          />
          <input
            type="text"
            className="input"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            placeholder="Role"
          />
        </form>
      </div>
      <button className="save" onClick={savePerson}>
        Save
      </button>
      {isGalleryOpen && (
        <Gallery
          isSelector={true}
          IDselector={setPictureID}
          URLselector={setImageUrl}
        />
      )}
    </FormContainer>
  );
};

export default PeopleForm;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;

  .form-elements {
    display: flex;
    gap: 15px;
    width: 100%;
  }
  .person-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .input {
    border-radius: 7px;
    background: none;
    height: 30px;
    border: 2px solid #ae7288;
  }
  .input:focus {
    padding-left: 10px;
    font-weight: bold;
  }

  .input:placeholder-shown {
    padding-left: 10px;
  }

  .select-image {
    min-width: 100px;
    background: none;
    border: 2px solid #ae7288;
    border-radius: 4px;
    font-size: 25px;
    cursor: pointer;
  }

  .img {
    width: 100px;
    max-height: 100%;
    cursor: pointer;
    box-shadow: 5px 5px 10px #585b70;
    border-radius: 5px;
    border: 1px solid black;

    padding: 2px;
    background: none;
  }
  .save {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin: 0 auto;
    font-weight: 600;
    border: none;
    width: 100%;
    height: 40px;
    background-color: #e8355f;
    color: #feecf2;
    cursor: pointer;
    border-radius: 3px;
    justify-content: center;
    border: 1px solid;
    gap: 10px;
  }
`;
