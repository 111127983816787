import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";

import WeddingAPI from "../api/wedding.api";
import Theme from "./theme";
import PageInvitation from "./pageInvitation";
import TabInvitation from "./tabInvitation";
import styled from "styled-components";

const SingleInvitation = () => {
  const wedding = useSelector((state) => state.wedding.invitation);
  const isUpdated = useSelector((state) => state.wedding.isUpdated);

  const [isLoading, setIsLoading] = useState(true);
  const [isNotSubscribed, setIsNotSubscribed] = useState(false);

  const username = useParams().username;
  const dispatch = useDispatch();
  const invitationID = useParams().invitationID || "";
  console.log(invitationID, "invite");
  useEffect(() => {
    WeddingAPI.fetchInvitation(
      dispatch,
      setIsLoading,
      setIsNotSubscribed,
      username,
      invitationID
    );
  }, [isUpdated]);

  if (document.getElementById("navbar")) {
    document.getElementById("navbar").style.display = "none";
  }

  if (isLoading) {
    return <></>;
  }

  if (isNotSubscribed) {
    return <Navigate to="/login" />;
  }
  console.log(wedding.theme[0]);
  const theme = wedding.theme[0];
  document.body.style.background = wedding.theme[0].backgroundColor;
  return (
    <Theme theme={theme}>
      <Invitation>
        {theme.pageStructure === "page" && <PageInvitation wedding={wedding} />}
        {theme.pageStructure === "tab" && <TabInvitation wedding={wedding} />}
      </Invitation>
    </Theme>
  );
};

export default SingleInvitation;
const Invitation = styled.div``;
