import axios from "axios";
import { authActions } from "../store/authSlice";

axios.defaults.withCredentials = true;

// const BASE_URL = "http://localhost:8000/";
const BASE_URL = "https://api-wedink-9u29u.ondigitalocean.app/";

const register = async (dispatch, data, setIsLoading) => {
  try {
    const response = await axios
      .create()
      .post(`${BASE_URL}accounts/register/`, data, {});
    if (response.data.isAuthenticated) {
      dispatch(authActions.login());
    } else {
      dispatch(authActions.logout());
    }
  } catch (err) {
    console.log(err.message);
    dispatch(authActions.logout());
  } finally {
    setIsLoading(false);
  }
};

const login = async (data, dispatch, setIsLoading) => {
  try {
    const response = await axios
      .create()
      .post(`${BASE_URL}accounts/login/`, data);

    if (response.data.isAuthenticated) {
      dispatch(authActions.login());
    } else {
      dispatch(authActions.logout());
    }
  } catch (err) {
    console.log(err.message);
  } finally {
    setIsLoading(false);
  }
};

const logout = async (setIsAuthenticated, setUser, navigate) => {
  try {
    const response = await axios.create().post(`${BASE_URL}accounts/logout/`);

    if (response.data.success) {
      setIsAuthenticated(false);
      setUser({});
      navigate("accounts/login");
    }
    if (!response.data.isSuccess) {
      setIsAuthenticated(true);
    }
  } catch (err) {
    console.log(err.message);
  }
};

const getUser = async (setIsLoading, dispatch) => {
  try {
    const response = await axios.create().get(`${BASE_URL}accounts/user/`);
    if (response.data.isAuthenticated) {
      dispatch(authActions.login());
      dispatch(authActions.setUser(response.data.user));
    }

    if (!response.data.isAuthenticated) {
      dispatch(authActions.logout());
      dispatch(authActions.setUser({}));
    }
  } catch (err) {
    console.log(err.message);
  } finally {
    setIsLoading(false);
  }
};

const AccountsAPI = {
  register,
  login,
  getUser,
  logout,
};

export default AccountsAPI;
