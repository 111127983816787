import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const Event = ({ data }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const dateTime = new Date(data.eventDateTime);
  const date = `
    ${(dateTime + "").substring(0, 3)}, ${(dateTime + "").substring(4, 16)}`;

  const toggleExpanded = () => {
    setIsExpanded((prev) => !prev);
  };
  return (
    <EventContainer>
      <div className="timeline">
        <div className="timeline-denote"></div>
      </div>
      <div className="event-details" onClick={toggleExpanded}>
        <div className="event-subview">
          <p className="event-name">{data.eventName}</p>
          <p className="date">{date}</p>
        </div>

        {isExpanded && (
          <motion.div
            initial={{ opacity: 0, y: 200 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
          >
            <p className="event-desc">{data.eventDesc}</p>
            <p className="location">{data.locationText}</p>
          </motion.div>
        )}
      </div>
    </EventContainer>
  );
};

export default Event;

const EventContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 90vw;
  margin: 5px auto;
  gap: 15px;
  justify-content: center;

  .event-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px 10% solid ${(props) => props.theme.titleFontColor};
    cursor: pointer;
  }
  .timeline {
    margin-top: 15px;
    /* background-color: #e8eaeb; */
    border-radius: 100%;
  }

  .timeline-denote {
    background-color: black;
    width: 7px;
    height: 7px;
    border-radius: 100%;
  }
  .event-name {
    width: 100%;
    text-align: left;
    font-size: 20px;
    font-family: ${(props) => props.theme.titleFontFamily};
    font-weight: 700;
    margin-top: 0;
    color: ${(props) => props.theme.titleFontColor};
  }

  .event-subview {
    display: flex;
    flex-direction: row;
  }
  .location {
    display: flex;
    align-items: center;
    margin-top: 0;
    gap: 20px;
    width: 100%;
    text-align: left;
    font-weight: 500;
    font-size: 13px;
    font-family: ${(props) => props.theme.contentFontFamily};
  }
  .underline {
    text-decoration: underline;
    text-underline-offset: 5px;
  }
  .event-desc {
    font-family: ${(props) => props.theme.contentFontFamily};
  }
  .date {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    font-family: ${(props) => props.theme.contentFontFamily};
  }

  .time {
    background-color: ${(props) => props.theme.backgroundColor};
    color: ${(props) => props.theme.titleFontColor};
    width: max-content;
    padding: 4px;
    border-radius: 5px;
    font-size: 12px;
  }
`;
