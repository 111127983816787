import React, { useState } from "react";
import styled from "styled-components";
import SingleGuest from "./singleGuest";

const AllGuests = ({ guests }) => {
  const [tempGuests, setTempGuests] = useState(guests);
  const [tab, setTab] = useState("0");

  const filterPending = () => {
    setTab("1");
    setTempGuests(
      guests.filter((guest) => {
        return !guest.isApproved;
      })
    );
  };

  const filterApproved = () => {
    setTab("2");

    setTempGuests(
      guests.filter((guest) => {
        return guest.isApproved;
      })
    );
  };

  const allGuests = () => {
    setTab("0");
    setTempGuests(guests);
  };

  return (
    <Guests>
      <div className="filters-tab">
        <button
          className={tab === "0" ? "tab active" : "tab"}
          onClick={allGuests}
        >
          All
        </button>
        <button
          className={tab === "1" ? "tab active" : "tab"}
          onClick={filterPending}
        >
          Pending
        </button>
        <button
          className={tab === "2" ? "tab active" : "tab"}
          onClick={filterApproved}
        >
          Approved
        </button>
      </div>
      {tempGuests.length > 0 &&
        tempGuests.map((guest) => {
          return <SingleGuest guest={guest} />;
        })}
    </Guests>
  );
};

export default AllGuests;

const Guests = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .filters-tab {
    display: flex;
    gap: 5px;
    justify-content: space-between;
  }

  .tab {
    width: 100%;
    border: none;
    background: none;
    border: 1px solid #ae7288;
    border-radius: 5px;
    height: 30px;
    font-weight: 600;
  }

  .active {
    background-color: #e8355f;
    color: #feecf2;
    border: #e8355f;
  }
`;
