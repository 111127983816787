import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import MediaAPI from "../api/media.api";
import Login from "../pages/login";
import AllMedia from "../components/allMedia";
import Uploader from "../components/uploadMedia";

const Gallery = ({ isSelector, IDselector, URLselector }) => {
  const allImages = useSelector((state) => state.media.allMedia);
  const isUpdated = useSelector((state) => state.media.isUpdated);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [isLoading, setIsLoading] = useState(true);




  const dispatch = useDispatch();

  useEffect(() => {
    MediaAPI.fetchAllMedia(dispatch, setIsLoading);
  }, [isUpdated]);

  if (isLoading) {
    return <></>;
  }
  return isLoggedIn ? (
    <GalleryContainer>
      <Uploader />
      <AllMedia
        images={allImages}
        isSelector={isSelector}
        IDselector={IDselector}
        URLselector={URLselector}
      />
    </GalleryContainer>
  ) : (
    <Login />
  );
};

export default Gallery;

const GalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 90vw;
  justify-content: center;
`;
