import React from "react";
import styled from "styled-components";
import Logo from "../media/logo-dark.png";

const footer = () => {
  return (
    <FooterContainer>
      <img src={Logo} alt="" className="logo" />
    </FooterContainer>
  );
};

export default footer;

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  padding-top: 5px;
  /* border-top: 1px solid lightgrey; */
  position: fixed;
  bottom: 0;
  width: 100%;
  .logo {
    width: 100px;
    height: 100%;
    margin: 0 auto;
    margin-top: 10px;
  }
`;
