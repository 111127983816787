import React from "react";
import styled from "styled-components";
import Header from "../components/invitationHeader";
import Couple from "../components/couple";
import Wishes from "../components/wishes";
import Shedule from "../components/shedule";
import People from "../components/people";
import CustomInvite from "../components/customInviteBar";

const PageInvitation = ({ wedding }) => {
  return (
    <Invitation>
      <Header wedding={wedding} />
      <Couple wedding={wedding} />
      <People wedding={wedding} />
      <Shedule wedding={wedding} />
      <Wishes wedding={wedding} />

      <CustomInvite wedding={wedding} />
    </Invitation>
  );
};

export default PageInvitation;

const Invitation = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin-top: 0;
  margin-bottom: 100px;
  background-color: ${(props) => props.theme.backgroundColor};
  color: ${(props) => props.theme.contentFontColor};
`;
