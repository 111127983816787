import React from "react";
import styled from "styled-components";
import Wish from "./singleWish";
import { MdOutlineExpandMore } from "react-icons/md";
import { motion } from "framer-motion";

const wishes = ({ wedding }) => {
  return (
    <WishesContainer>
      <p className="title-inv underline">Wishes</p>

      {wedding.guests.map((wish) => {
        return (
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.3 }}
          >
            <Wish data={wish} key={wish.id} />
          </motion.div>
        );
      })}

      <p className="load-more">
        <MdOutlineExpandMore /> More wishes
      </p>
    </WishesContainer>
  );
};

export default wishes;

const WishesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: ${(props) => props.theme.titleFontColor};

  .title-inv {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    color: ${(props) => props.theme.titleFontColor};
    font-family: ${(props) => props.theme.titleFontFamily};
    font-weight: 800;
  }

  .underline {
    text-decoration: underline;
    text-underline-offset: 5px;
    color: ${(props) => props.theme.titleFontColor};
    font-family: ${(props) => props.theme.titleFontFamily};
  }

  .load-more {
    text-align: center;
  }
`;
