import axios from "axios";
import { weddingActions } from "../store/weddingSlice";

axios.defaults.withCredentials = true;

// const BASE_URL = "http://localhost:8000/";
const BASE_URL = "https://api-wedink-9u29u.ondigitalocean.app/";

const newOrder = async (
  // setIsProcessing,
  setIsSuccess,
  setIsFailed,
  weddingID,
  dispatch
) => {
  try {
    const response = await axios.create().post(`${BASE_URL}order/create-order`);
    const createdOrderId = response.data.orderID;
    const amount = response.data.amount + "";

    if (response.data.success) {
      var options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        amount: amount,
        currency: "INR",
        name: "Wedink",
        description: "Test Transaction",
        image: "https://wedink-images.s3.ap-south-1.amazonaws.com/LOGO.png",
        order_id: createdOrderId,
        handler: async function (response) {
          const data = {
            amount: amount,
            createdOrderId: createdOrderId,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
            weddingID: weddingID,
          };
          console.log(response);
          const result = await axios.post(`${BASE_URL}order/verify`, data);
          console.log(result);

          if (result.data.success & result.data.captured) {
            dispatch(weddingActions.refreshWedding());
            setIsSuccess(true);
          } else {
            setIsFailed(true);
          }
        },
        theme: {
          color: "#AE7288",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    }
  } catch (err) {
    console.log(err.message);
    setIsFailed(true);
  }
};

const RazorpayAPI = {
  newOrder,
};
export default RazorpayAPI;
