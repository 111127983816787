import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Login from "./login";
import WeddingAPI from "../api/wedding.api";
import SingleWeddingMini from "../components/singleWeddingMini";
import gif from "../media/create-wedding2.gif";
import { useNavigate } from "react-router-dom";

const Weddings = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [isLoading, setIsLoading] = useState(true);

  const allWeddings = useSelector((state) => state.wedding.allWeddings);
  console.log(allWeddings);

  const dispatch = useDispatch();

  useEffect(() => {
    WeddingAPI.fetchAllWeddings(dispatch, setIsLoading);
  }, []);

  const navigate = useNavigate();
  const handleCreate = () => {
    navigate("/weddings/new");
  };

  console.log();
  if (isLoading) {
    return <></>;
  }
  return isLoggedIn ? (
    <WeddingsContainer>
      {Object.keys(allWeddings).length > 0 ? (
        <div>
          <p className="title">All Weddings</p>
          {allWeddings.map((wedding) => {
            return <SingleWeddingMini wedding={wedding} key={wedding._id} />;
          })}
        </div>
      ) : (
        <div className="add-wedding-cta">
          <p className="title">
            Create your first wedding website in under 2 minutes
          </p>
          <img src={gif} className="gif" alt="" onClick={handleCreate} />
          <button className="create-wedding" onClick={handleCreate}>
            Create Wedding
          </button>
        </div>
      )}
    </WeddingsContainer>
  ) : (
    <Login />
  );
};

export default Weddings;

const WeddingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  /* justify-content: center; */
  min-height: 80vh;

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    /* font-family: "Shadows Into Light"; */
    font-weight: 600;
  }

  .underline {
    text-decoration: underline;
    text-underline-offset: 5px;
  }

  .add-wedding-cta {
    display: flex;
    flex-direction: column;
    width: 90vw;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  .gif {
    display: flex;
    width: 80%;
    margin: 0;
  }
  .create-wedding {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-top: 3px;
    font-weight: 600;
    border: none;
    width: 100%;
    height: 40px;
    background-color: #e8355f;
    color: #feecf2;
    cursor: pointer;
    border-radius: 3px;
    justify-content: center;
    border: 1px solid;
    gap: 10px;
  }
`;
