import React from "react";
import styled from "styled-components";

const PrePayment = ({ checkout }) => {
  return (
    <PrePaymentContainer className="pre-payment">
      <p className="title">Checkout</p>
      <div className="seperator"></div>
      <div className="bill-features">
        <div className="bill-item">
          <p className="feature">Your wedding website</p>
          <p className="feature">499</p>
        </div>
        <div className="bill-item">
          <p className="feature">Happiness guranteed</p>
          <p className="feature">FREE</p>
        </div>
        <div className="bill-item">
          <p className="feature">1:1 WhatsApp Support</p>
          <p className="feature">FREE</p>
        </div>
        <div className="bill-item">
          <p className="feature">100% data security</p>
          <p className="feature">FREE</p>
        </div>
        <div className="bill-item">
          <p className="feature">90 Days Validity</p>
          <p className="feature">FREE</p>
        </div>
        <div className="bill-item">
          <p className="feature">Personalized invite option</p>
          <p className="feature">FREE</p>
        </div>
        <div className="bill-item">
          <p className="feature">Guests stay updated</p>
          <p className="feature">FREE</p>
        </div>
      </div>
      <p className="tax">Taxes Included</p>
      <div className="bill-item">
        <p className="feature highlight">TOTAL :</p>
        <p className="feature highlight">Rs 499</p>
      </div>
      <button className="buy-btn" onClick={checkout}>
        Pay
      </button>
    </PrePaymentContainer>
  );
};

export default PrePayment;

const PrePaymentContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 60vh;
  border-radius: 5px;
  margin: auto;
  padding: 0 15px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23333' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='34' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 10px;

  .title {
    font-weight: 600;
    font-size: larger;
    font-size: 25px;
    margin: 0;
    margin-top: 20px;
    font-family: "Roboto Mono";
  }
  .price {
    font-weight: 600;
    font-size: larger;
    margin: 0;
    margin-top: 20px;
    font-family: "Roboto Mono";
  }
  .seperator {
  }
  .bill-item {
    display: flex;
    justify-content: space-between;
    padding-bottom: 3px;
    border-bottom: 1px dashed;
  }
  .highlight {
    font-weight: 900;
  }
  .bill-features {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    font-weight: 500;
    gap: 10px;
    width: 100%;
  }
  .total {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .tax {
    margin: 0;
    font-family: "Roboto Mono";
    font-size: 12px;
  }
  .feature {
    margin: 0;
    font-family: "Roboto Mono";
    margin: 0 20px;
  }
  .buy-btn {
    display: flex;
    cursor: pointer;
    align-items: center;
    font-weight: 600;
    border: none;
    width: 150px;
    height: 40px;
    background-color: #e8355f;
    color: #feecf2;
    cursor: pointer;
    border-radius: 3px;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
  }
`;
