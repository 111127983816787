import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { MdDelete } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import MediaAPI from "../api/media.api";

const SingleImage = ({ image, isSelector, IDselector, URLselector }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteOption, setDeleteOption] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  const dispatch = useDispatch();

  const toggleDelete = () => {
    setDeleteOption((prev) => !prev);
  };

  const toggleSelected = () => {
    setIsSelected((prev) => !prev);
  };

  const selectImageID = () => {
    if (IDselector) {
      IDselector(image._id);
    }

    if (URLselector) {
      URLselector(image.mediaURL);
    }
  };
  const handleDelete = (e) => {
    e.preventDefault();
    setIsLoading(true);
    MediaAPI.deleteMedia(setIsLoading, image._id, dispatch);
    console.log("aaaa");
  };

  return (
    <ImageBox>
      <img
        src={image.mediaURL}
        alt=""
        className={isSelected ? "image selected" : "image"}
        onClick={isSelector ? toggleSelected : toggleDelete}
      />
      {!isSelector && isDeleteOption ? (
        !isLoading ? (
          <button className="delete" onClick={handleDelete}>
            <MdDelete /> Delete
          </button>
        ) : (
          <button className="delete" onClick={(e) => e.preventDefault()}>
            <div class="loader"></div>
          </button>
        )
      ) : (
        <></>
      )}

      {isSelector & isSelected ? (
        !isLoading ? (
          <button className="delete" onClick={selectImageID}>
            <TiTick /> Select
          </button>
        ) : (
          <button className="delete" onClick={(e) => e.preventDefault()}>
            <div class="loader"></div>
          </button>
        )
      ) : (
        <></>
      )}
    </ImageBox>
  );
};

export default SingleImage;

const ImageBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3px;
  cursor: pointer;

  .image {
    width: 200px;
    height: 200px;
    border-radius: 7px;
    object-fit: cover;
    object-position: auto;
  }
  .delete {
    display: flex;
    gap: 3px;
    z-index: 1000;
    width: 100%;
    border-radius: 5px;
    color: #8f5854;
    background-color: #f5eceb;
    border: none;
    font-weight: 500;
    margin: auto auto;
    height: 35px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    z-index: 1000;
    cursor: pointer;
  }
  .toggle-btn-container {
    background: none;
    border: none;
  }

  .selected {
    border: 2px solid black;
    padding: 2px;
    box-shadow: 5px 5px 10px #585b70;
  }
  .loader {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #8f5854; /* Blue */
    border-radius: 50%;
    width: 7px;
    height: 7px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @media only screen and (max-width: 600px) {
    .image {
      width: 150px;
      height: 150px;
      border-radius: 7px;
      object-fit: cover;
      object-position: auto;
    }
  }
`;
