import React, { useState } from "react";
import styled from "styled-components";
import WeddingAPI from "../api/wedding.api";
import Selector from "./selector";

const InvitationForm = ({ wedding }) => {
  const [peopleID, setPeopleID] = useState([]);
  const [eventsID, setEventsID] = useState([]);
  const [guestName, setGuestName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [isWishesHidden, setIsWishesHidden] = useState(false);
  const [isEventsHidden, setIsEventsHidden] = useState(false);
  const [isPeopleHidden, setIsPeopleHidden] = useState(false);
  const [isCoupleHidden, setIsCoupleHidden] = useState(false);
  const [isStoryHidden, setIsStoryHidden] = useState(false);
  const [id, setId] = useState("");

  const createInvite = (e) => {
    e.preventDefault();
    const data = {
      guestName,
      peopleID,
      eventsID,
      weddingID: wedding._id,
    };
    WeddingAPI.createInvite(setIsSuccess, setIsLoading, data, setId);
  };

  let LINK = "";
  if (isSuccess) {
    LINK = `https://thewedink.in/${wedding.username}/${id}`;
  }

  return (
    <FormContainer>
      <form className="form" onSubmit={createInvite}>
        <input
          type="text"
          value={guestName}
          onChange={(e) => setGuestName(e.target.value)}
          className="input"
          placeholder="Name of Guest"
        />
        <div className="selectors">
          <p className="title">Select people to hide</p>
          {wedding.people.map((person) => {
            return (
              <Selector
                list={peopleID}
                setList={setPeopleID}
                value={person}
                labelField="personName"
              />
            );
          })}

          <p className="title">Select events to hide</p>
          {wedding.events.map((event) => {
            return (
              <Selector
                list={eventsID}
                setList={setEventsID}
                value={event}
                labelField="eventName"
              />
            );
          })}
        </div>
        <button type="submit" className="create-invite" onSubmit={createInvite}>
          {isLoading ? <div className="loader"></div> : "Create Invite"}
        </button>
      </form>
      {isSuccess && (
        <a
          className="create-invite invite-link"
          href={LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          View Invite
        </a>
      )}
    </FormContainer>
  );
};

export default InvitationForm;

const FormContainer = styled.div`
  .create-invite {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-top: 20px;
    font-weight: 600;
    border: none;
    width: 100%;
    height: 40px;
    color: #e8355f;
    background-color: #feecf2;
    cursor: pointer;
    border-radius: 3px;
    justify-content: center;
    border: 1px solid;
    gap: 10px;
    text-decoration: none;
  }
  .invite-link {
    background-color: #e8355f;
    color: #feecf2;
  }
  .loader {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #8f5854; /* Blue */
    border-radius: 50%;
    width: 7px;
    height: 7px;
    animation: spin 2s linear infinite;
  }

  .input {
    border-radius: 7px;
    height: 40px;
    width: 100%;
    border: 2px solid black;
    padding-left: 10px;
    font-weight: bold;
    background-color: #feecf2;
  }
  .input:focus {
    padding-left: 10px;
    font-weight: bold;
  }

  .input:placeholder-shown {
    padding-left: 10px;
  }
`;
