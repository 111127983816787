import React from "react";
import styled from "styled-components";
import { AiFillHeart } from "react-icons/ai";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const SingleWeddingMini = ({ wedding }) => {
  const navigate = useNavigate();
  const handleViewWedding = () => {
    navigate(`/${wedding.username}/admin`);
  };
  return (
    <Wedding>
      <div className="wedding-container">
        <div className="wedding-details">
          <p className="couple-name">
            {wedding.groomName} <AiFillHeart />
            {wedding.brideName}
          </p>
          <p className="username">@{wedding.username}</p>
        </div>
        {!wedding.hasSubscribed && <p className="active"></p>}
      </div>
      {/* <p className="story">{wedding.story}</p> */}

      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.3 }}
      >
        <button className="view-btn" onClick={handleViewWedding}>
          View <BsArrowRight />
        </button>
      </motion.div>
    </Wedding>
  );
};

export default SingleWeddingMini;

const Wedding = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  margin: 0 auto;
  padding: 5px;
  /* border: 1px solid #17252b; */

  .wedding-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 7px;
  }

  .couple-name {
    margin-top: 10px;
    margin-bottom: 3px;
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
    font-weight: 500;
    gap: 3px;
    font-size: 20px;
  }
  .wedding-container {
    display: flex;
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid lightgrey;
  }

  .username {
    margin: 0;
    width: 100%;
    font-size: 13px;
  }

  .view-btn {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-top: 3px;
    font-weight: 600;
    border: none;
    width: 100%;
    height: 40px;
    background-color: #e8355f;
    color: #feecf2;
    cursor: pointer;
    font-size: 13px;
    border-radius: 3px;
    justify-content: center;
    gap: 10px;
  }

  .active {
    display: flex;
    align-items: center;
    margin-right: 10px;
    font-size: small;
    background-color: green;
    height: 7px;
    width: 7px;
    border-radius: 50%;
  }

  .story {
    margin: 10px 0;
    font-size: 14px;
  }
`;
