import axios from "axios";
import { weddingActions } from "../store/weddingSlice";

axios.defaults.withCredentials = true;

// const BASE_URL = "http://localhost:8000/";
const BASE_URL = "https://api-wedink-9u29u.ondigitalocean.app/";

const createEvent = async (dispatch, setIsLoading, data) => {
  try {
    const response = await axios.create().post(`${BASE_URL}events/new`, data);
    if (response.data.success) {
      dispatch(weddingActions.refreshWedding());
    }
  } catch (err) {
    console.log(err.message);
  } finally {
    setIsLoading(false);
  }
};

const EventAPI = { createEvent };

export default EventAPI;
