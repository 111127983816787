import React from "react";
import styled from "styled-components";
import wishes from "../media/wishes.png";

const Features = () => {
  return (
    <Container>
      <img className="feature-img" src={wishes} />
      <p className="feature-text">Your guests can wish you on the invitaion </p>
      <img className="feature-img" src={wishes} />
      <p className="feature-text">Your guests can wish you on the invitaion </p>
    </Container>
  );
};

export default Features;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  margin: 0 auto;
  /* background-color: #8a465f; */

  .feature-text {
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    margin: 10px auto;
  }

  .feature-img {
    width: 90vw;
    border-radius: 5px;
    box-shadow: 5px 5px 20px #8a6a73;
    margin-bottom: 10px;
  }
`;
