import axios from "axios";
import { weddingActions } from "../store/weddingSlice";

axios.defaults.withCredentials = true;

// const BASE_URL = "http://localhost:8000/";
const BASE_URL = "https://api-wedink-9u29u.ondigitalocean.app/";

const updateAppearance = async (
  dispatch,
  appearanceID,
  setIsLoading,
  data,
  toggleEdit
) => {
  try {
    const response = await axios
      .create()
      .put(`${BASE_URL}theme/${appearanceID}/update`, data);
    if (response.data.success) {
      dispatch(weddingActions.refreshWedding());
      toggleEdit();
    }
  } catch (err) {
    console.log(err.message);
  } finally {
    setIsLoading(false);
  }
};

const AppearanceAPI = {
  updateAppearance,
};
export default AppearanceAPI;
