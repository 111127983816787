import React from "react";
import AllGuests from "./allGuests";
import styled from "styled-components";

const guestsPrivate = ({ wedding }) => {
  return (
    <GuestsContainer>
      <div className="title-nav">
        <p className="title">Wishes</p>
      </div>
      <AllGuests guests={wedding.guests} />
    </GuestsContainer>
  );
};

export default guestsPrivate;

const GuestsContainer = styled.div`
  display: flex;
  width: 90vw;
  margin: 0 auto;
  min-height: 70vh;
  flex-direction: column;

  .title {
    font-weight: 600;
    font-size: larger;
    color: #ae7288;
  }

  .title-nav {
    display: flex;
    justify-content: space-between;
  }
`;
