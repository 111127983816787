import React, { useState } from "react";
import styled from "styled-components";
import GuestApi from "../api/guest.api";
import { useDispatch } from "react-redux";

const AddWishForm = ({ wedding, toggleForm }) => {
  const [guestName, setGuestName] = useState("");
  const [wish, setWish] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const addWish = (e) => {
    e.preventDefault();
    const data = {
      guestName,
      wish,
      weddingID: wedding._id,
    };
    GuestApi.newGuest(dispatch, setIsLoading, data, toggleForm);
  };
  return (
    <WishForm>
      <form className="form" onSubmit={addWish}>
        <p className="title"></p>
        <input
          type="text"
          className="input"
          value={guestName}
          onChange={(e) => setGuestName(e.target.value)}
          placeholder="Your Name"
        />
        <input
          type="text"
          className="input"
          value={wish}
          onChange={(e) => setWish(e.target.value)}
          placeholder="Drop your wish"
        />
        <button className="add-btn" onClick={addWish} type="submit">
          Wish
        </button>
      </form>
    </WishForm>
  );
};

export default AddWishForm;

const WishForm = styled.div`
  .form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .input {
    border-radius: 7px;
    height: 30px;
    border: 2px solid black;
    padding-left: 10px;
    font-weight: bold;
    font-family: ${(props) => props.theme.contentFontFamily};
  }
  .input:focus {
    padding-left: 10px;
    font-weight: bold;
    font-family: ${(props) => props.theme.contentFontFamily};
  }

  .input:placeholder-shown {
    padding-left: 10px;
    font-family: ${(props) => props.theme.contentFontFamily};
  }
  .add-btn {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-top: 3px;
    font-weight: 600;
    border: none;
    width: 100%;
    height: 40px;
    background-color: ${(props) => props.theme.backgroundColor};
    color: ${(props) => props.theme.titleFontColor};
    cursor: pointer;
    font-family: ${(props) => props.theme.contentFontFamily};
    border-radius: 3px;
    justify-content: center;
    gap: 10px;
  }
`;
