import React, { useState } from "react";
import styled from "styled-components";
import AllEvents from "./allEvents";
import EventForm from "./eventForm";

const EventsPrivate = ({ wedding }) => {
  const [isAdding, setIsAdding] = useState(false);

  const toggleAdding = () => {
    setIsAdding((prev) => !prev);
  };
  return (
    <EventContainer>
      <div className="title-nav">
        <p className="title">Shedule</p>

        <button className="add-event-btn" onClick={toggleAdding}>
          {isAdding ? "Close" : "Add Event"}
        </button>
      </div>
      {isAdding && (
        <EventForm weddingID={wedding._id} toggleAdding={toggleAdding} />
      )}

      {Object.keys(wedding.events).length === 0 && (
        <button className="empty-btn" onClick={toggleAdding}>
          Add Event
        </button>
      )}
      <AllEvents events={wedding.events} />
    </EventContainer>
  );
};

export default EventsPrivate;

const EventContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 90vw;
  min-height: 70vh;
  margin: 0 auto;
  gap: 10px;
  .title {
    font-weight: 600;
    font-size: larger;
    color: #ae7288;
  }
  .empty-btn {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-top: 3px;
    font-weight: 600;
    border: none;
    width: 150px;
    height: 40px;
    background-color: #e8355f;
    color: #feecf2;
    cursor: pointer;
    border-radius: 3px;
    justify-content: center;
    border: 1px solid;
    gap: 10px;
    margin: auto;
  }

  .title-nav {
    display: flex;
    justify-content: space-between;
  }

  .add-event-btn {
    border: none;
    background: none;
    font-weight: 600;
    color: #e8355f;
    cursor: pointer;
  }
`;
