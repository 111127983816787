import React, { useCallback, useRef, useState, useEffect } from "react";
import { HexColorPicker } from "react-colorful";
import styled from "styled-components";

import useClickOutside from "./useClickOutside";

const ColorPicker = ({
  value,
  setCurTheme,
  label,
  isEditing,
  name,
  curTheme,
}) => {
  const popover = useRef();
  const [isOpen, toggle] = useState(false);
  const [color, setColor] = useState(value);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  useEffect(() => {
    setCurTheme({ ...curTheme, [name]: color });
  }, [color]);

  return (
    <Picker>
      <div className="picker">
        <div className="swatch-container">
          <div
            className="swatch"
            style={{ backgroundColor: value }}
            onClick={isEditing ? () => toggle(true) : null}
          />
        </div>
        {isOpen & isEditing ? (
          <div className="popover" ref={popover}>
            <HexColorPicker color={color} onChange={setColor} name={name} />
          </div>
        ) : (
          <></>
        )}
        <label className="label">{label}</label>
      </div>
    </Picker>
  );
};

export default ColorPicker;

const Picker = styled.div`
  display: flex;

  .picker {
    display: flex;
    flex-direction: row;
    gap: 10px;
    position: relative;
    align-items: center;
  }
  .swatch-container {
    padding: 2px;
    border: 2px solid black;
    border-radius: 9px;
    max-width: 100%;
    max-height: 100%;
  }
  .swatch {
    width: 28px;
    max-width: 100%;
    max-height: 100%;
    height: 28px;
    border-radius: 9px;
    cursor: pointer;
  }

  .popover {
    top: calc(100% + 2px);
    left: 0;
    border-radius: 9px;
    position: absolute;
    z-index: 1000;
    margin-top: 5px;
  }
  .label {
    font-weight: 600;
  }
`;
