import React from "react";
import styled from "styled-components";
import picture1 from "../media/picture1.png";
import wishes from "../media/wishes.png";
import HomeCard from "./homeCard";

const GetStarted = () => {
  return (
    <CTA>
      <HomeCard
        img={picture1}
        title="Personalised wedding website under 2 minutes"
      />
    </CTA>
  );
};

export default GetStarted;

const CTA = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  margin: 0 auto;
  height: 90vh;
  align-items: center;
  justify-content: center;

  .feature-text {
    font-weight: 500;
    text-align: center;
    color: #ae7288;
  }
  .feature-title {
    font-weight: 600;
    font-size: 20px;
    text-align: center;
  }
`;
