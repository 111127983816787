import React, { useState } from "react";
import styled from "styled-components";
import GuestAPI from "../api/guest.api";
import { useDispatch } from "react-redux";

const SingleGuest = ({ guest }) => {
  const [isLoading, setIsLoading] = useState(false);
  console.log(guest);
  const dispatch = useDispatch();

  const toggleApprove = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      isApproved: !guest.isApproved,
    };
    GuestAPI.updateGuest(dispatch, guest._id, setIsLoading, data);
  };
  return (
    <GuestContainer>
      <div className="wish-detail">
        <button
          className={guest.isApproved ? "approve-btn approved" : "approve-btn"}
          onClick={toggleApprove}
        ></button>

        <p className={isLoading ? "wish loading" : "wish"}>{guest.wish}</p>
      </div>
      <p className="person-name">{guest.guestName}</p>
    </GuestContainer>
  );
};

export default SingleGuest;

const GuestContainer = styled.div`
  display: flex;
  flex-direction: column;

  .wish-detail {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
  .wish {
    margin: 0;
    margin-bottom: 2px;
  }
  .person-name {
    margin: 0;
    margin-left: 28px;
    font-weight: 600;
    font-size: 13px;
  }
  .approve-btn {
    border: 2px solid #ae7288;
    min-width: 13px;
    min-height: 15px;
    border-radius: 50%;
    cursor: pointer;
  }
  .approved {
    background-color: #e8355f;
    border: 2px solid #e8355f;
  }

  .loading {
    animation: blinker 1s linear infinite;
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
`;
