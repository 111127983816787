import React from "react";
import styled from "styled-components";
import Event from "./event";

const shedule = ({ wedding }) => {
  return (
    <SheduleContainer>
      <p className="title-inv underline">Shedule</p>
      {wedding.events.map((event) => {
        return <Event data={event} key={event._id} />;
      })}
    </SheduleContainer>
  );
};
export default shedule;

const SheduleContainer = styled.div`
  display: flex;
  flex-direction: column;

  .title-inv {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    color: ${(props) => props.theme.titleFontColor};
    font-family: ${(props) => props.theme.titleFontFamily};
    font-weight: 800;
  }
  .underline {
    text-decoration: underline;
    text-underline-offset: 5px;
    color: ${(props) => props.theme.titleFontColor};
    font-family: ${(props) => props.theme.titleFontFamily};
  }
`;
