import axios from "axios";
import { weddingActions } from "../store/weddingSlice";

axios.defaults.withCredentials = true;

// const BASE_URL = "http://localhost:8000/";
const BASE_URL = "https://api-wedink-9u29u.ondigitalocean.app/";

const addPerson = async (dispatch, setIsLoading, data) => {
  try {
    const response = await axios.create().post(`${BASE_URL}people/new`, data);
    if (response.data.success) {
      dispatch(weddingActions.refreshWedding());
    }
  } catch (err) {
    console.log(err.message);
  } finally {
    setIsLoading(false);
  }
};

const updatePeople = async (
  dispatch,
  peopleID,
  setIsLoading,
  data,
  setIsEditing
) => {
  try {
    const response = await axios
      .create()
      .put(`${BASE_URL}people/${peopleID}/update`, data);
    if (response.data.success) {
      dispatch(weddingActions.refreshWedding());
      setIsEditing(false);
    }
  } catch (err) {
    console.log(err.message);
  } finally {
    setIsLoading(false);
  }
};

const PeopleAPI = {
  updatePeople,
  addPerson,
};
export default PeopleAPI;
