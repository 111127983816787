import React from "react";
import styled from "styled-components";

const InvitationTabSwitcher = ({ tab, setTab }) => {
  const switchTab = (e) => {
    setTab(e.target.value);
  };
  return (
    <Switcher>
      <div className="nav-buttons">
        <button
          className={tab === "0" ? "nav-btn active" : "nav-btn"}
          value="0"
          onClick={switchTab}
        >
          Couple
        </button>
        <button
          className={tab === "1" ? "nav-btn active" : "nav-btn"}
          value="1"
          onClick={switchTab}
        >
          People
        </button>
        <button
          className={tab === "2" ? "nav-btn active" : "nav-btn"}
          value="2"
          onClick={switchTab}
        >
          Shedule
        </button>
        <button
          className={tab === "3" ? "nav-btn active" : "nav-btn"}
          value="3"
          onClick={switchTab}
        >
          Wishes
        </button>
      </div>
    </Switcher>
  );
};

export default InvitationTabSwitcher;

const Switcher = styled.div`
  margin-bottom: 20px;
  .nav-buttons {
    display: flex;
    justify-content: space-between;
  }

  .nav-btn {
    width: 100%;
    border: none;
    background: none;
    height: 30px;
    font-weight: 600;
    cursor: pointer;
    height: 40px;
    color: ${(props) => props.theme.contentFontColor};
    font-family: ${(props) => props.theme.contentFontFamily};
  }

  .active {
    background-color: ${(props) => props.theme.buttonBackgroundColor};
    color: ${(props) => props.theme.buttonTextColor};
    border: #e8355f;
  }
`;
