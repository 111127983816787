import React, { useState } from "react";
import styled from "styled-components";
import Header from "../components/invitationHeader";
import Couple from "../components/couple";
import Wishes from "../components/wishes";
import Shedule from "../components/shedule";
import People from "../components/people";
import CustomInvite from "../components/customInviteBar";

import SwitchTab from "../components/invitationTabSwitcher";

const TabInvitation = ({ wedding }) => {
  const [tab, setTab] = useState("0");
  const TABS_DATA = [
    <Couple wedding={wedding} />,
    <People wedding={wedding} />,
    <Shedule wedding={wedding} />,
    <Wishes wedding={wedding} />,
  ];
  return (
    <Invitation>
      <Header wedding={wedding} />
      <SwitchTab setTab={setTab} tab={tab} />
      {TABS_DATA[parseInt(tab)]}
      <CustomInvite wedding={wedding} />
    </Invitation>
  );
};

export default TabInvitation;

const Invitation = styled.div`
  margin-bottom: 100px;
  background-color: ${(props) => props.theme.backgroundColor};
  color: ${(props) => props.theme.contentFontColor};
`;
